import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
  selector: 'app-naviconbar',
  standalone: true,
  imports: [RouterModule, MatMenuModule, MatButtonModule, MatIconModule, MatTooltipModule],
  templateUrl: './naviconbar.component.html',
  styleUrl: './naviconbar.component.scss'
})
export class NaviconbarComponent {

	constructor(public localAuth: AuthenticationService) {

	}

}
