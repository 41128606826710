import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmailRequest } from '../models/emailrequest.model';
import { UrlService } from './url.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class EmailrequestService {

	private svcUrl = '/EmailRequests';

	constructor(private http: HttpClient, private url: UrlService, private auth: AuthenticationService) { }

	getEmailRequest(id: number): Observable<EmailRequest> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<EmailRequest>(this.url.getBaseURL() + this.svcUrl + '/' + id, { headers });
	}

	getEmailRequestsForBrokerId(id: number, isDismissed?: boolean | null, itemStart?: number | null, itemCount?: number | null) {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		let params = new HttpParams()
			.set('IsDismissed', (isDismissed == null ? false : isDismissed))
			.set('ItemStart', (itemStart == null ? 0  : itemStart))
			.set('ItemCount', (itemCount == null ? 100 : itemCount));
		return this.http.get<EmailRequest[]>(this.url.getBaseURL() + this.svcUrl + '/Broker/' + id, { headers, params });
	}

	getEmailRequestsForUserId(id: string, isDismissed?: boolean | null, itemStart?: number | null, itemCount?: number | null) {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		let params = new HttpParams()
			.set('IsDismissed', (isDismissed == null ? false : isDismissed))
			.set('ItemStart', (itemStart == null ? 0 : itemStart))
			.set('ItemCount', (itemCount == null ? 100 : itemCount));
		return this.http.get<EmailRequest[]>(this.url.getBaseURL() + this.svcUrl + '/User/' + id, { headers, params });
	}

	dismissBatchEmailRequests(ids: number[]): Observable<EmailRequest[]> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<EmailRequest[]>(this.url.getBaseURL() + this.svcUrl + '/DismissBatch', ids, { headers });
	}

	dismissEmailRequest(id: number): Observable<EmailRequest> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<EmailRequest>(this.url.getBaseURL() + this.svcUrl + '/Dismiss/' + id, {}, { headers });
	}

	createEmailRequest(item: EmailRequest): Observable<EmailRequest> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<EmailRequest>(this.url.getBaseURL() + this.svcUrl, item, { headers });
	}

	createEmailRequestWithAttachment(file: FormData): Observable<EmailRequest> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<EmailRequest>(this.url.getBaseURL() + this.svcUrl + '/WithAttachment', file, { headers });
	}

	createEmailRequestWithExternalEmailAttachment(item: EmailRequest): Observable<EmailRequest[]> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<EmailRequest[]>(this.url.getBaseURL() + this.svcUrl + '/WithExternalEmailAttachment', item, { headers });
	}


}
