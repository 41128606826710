<div class="parent-frame">
	<mat-drawer-container class="drawer-container" autosize>
		<mat-drawer #drawer class="drawer-flyout" mode="side">
			<button mat-flat-button color="primary">Save New User</button>
			<button mat-flat-button color="warn" (click)="cancelClicked(drawer)">Cancel</button>
		</mat-drawer>

		<div class="body-frame">
			<div class="nav-bar">
				<app-naviconbar></app-naviconbar>
			</div>

			<div class="nav-bar">
				<button mat-flat-button color="primary" (click)="drawer.open()">Add User</button>
				<button mat-flat-button color="primary" (click)="refreshItems()">Refresh User List</button>
			</div>

			<div>
				<table [ngClass]="{ 'loading-animation' : isLoading }" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef> Id </th>
						<td mat-cell *matCellDef="let item"> {{item.user_id}} </td>
					</ng-container>

					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef> Name </th>
						<td mat-cell *matCellDef="let item"> {{item.name}} </td>
					</ng-container>

					<ng-container matColumnDef="email">
						<th mat-header-cell *matHeaderCellDef> Email </th>
						<td mat-cell *matCellDef="let item"> {{item.email}} </td>
					</ng-container>

					<ng-container matColumnDef="brokerId">
						<th mat-header-cell *matHeaderCellDef> Broker Id </th>
						<td mat-cell *matCellDef="let item"> {{item.user_metadata.brokerId}} </td>
					</ng-container>

					<ng-container matColumnDef="lastlogin">
						<th mat-header-cell *matHeaderCellDef> Last Login </th>
						<td mat-cell *matCellDef="let item"> {{item.last_login | date:'full'}} </td>
					</ng-container>

					<ng-container matColumnDef="lastip">
						<th mat-header-cell *matHeaderCellDef> Last IP </th>
						<td mat-cell *matCellDef="let item"> {{item.last_ip }} </td>
					</ng-container>

					<ng-container matColumnDef="loginscount">
						<th mat-header-cell *matHeaderCellDef> Logins Count </th>
						<td mat-cell *matCellDef="let item"> {{item.logins_count }} </td>
					</ng-container>

					<ng-container matColumnDef="createdat">
						<th mat-header-cell *matHeaderCellDef> Created </th>
						<td mat-cell *matCellDef="let item"> {{item.created_at | date:'full'}} </td>
					</ng-container>

					<ng-container matColumnDef="updatedat">
						<th mat-header-cell *matHeaderCellDef> Updated </th>
						<td mat-cell *matCellDef="let item"> {{item.updated_at | date:'full'}} </td>
					</ng-container>

					<ng-container matColumnDef="emailverified">
						<th mat-header-cell *matHeaderCellDef> Email Verified </th>
						<td mat-cell *matCellDef="let item"> {{item.email_verified}} </td>
					</ng-container>




					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;" (click)="rowClicked(row)"></tr>

				</table>
			</div>
		</div>

	</mat-drawer-container>
</div>

