import { Routes } from '@angular/router';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { AienginesComponent } from './features/aiengines/aiengines.component';
import { BrokersComponent } from './features/brokers/brokers.component';
import { EmailrequestsComponent } from './features/emailrequests/emailrequests.component';
import { ParsedemailsComponent } from './features/parsedemails/parsedemails.component';
import { ErpconnectionsComponent } from './features/erpconnections/erpconnections.component';
import { ErpsystemsComponent } from './features/erpsystems/erpsystems.component';
import { ErpsubmissionattemptsComponent } from './features/erpsubmissionattempts/erpsubmissionattempts.component';
import { WorkflowComponent } from './features/workflow/workflow.component';
import { DragtestComponent } from './features/dragtest/dragtest.component';
import { UsersComponent } from './features/users/users.component';
import { AuthGuard } from '@auth0/auth0-angular';

export const routes: Routes = [
	{ path: '', component: DashboardComponent, canActivate: [AuthGuard] },
	{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
	{ path: 'aiengines', component: AienginesComponent, canActivate: [AuthGuard] },
	{ path: 'brokers', component: BrokersComponent, canActivate: [AuthGuard]  },
	{ path: 'emailrequests', component: EmailrequestsComponent, canActivate: [AuthGuard] },
	{ path: 'parsedemails', component: ParsedemailsComponent, canActivate: [AuthGuard] },
	{ path: 'erpconnections', component: ErpconnectionsComponent, canActivate: [AuthGuard] },
	{ path: 'erpsystems', component: ErpsystemsComponent, canActivate: [AuthGuard] },
	{ path: 'erpsubmissionattempts', component: ErpsubmissionattemptsComponent, canActivate: [AuthGuard] },
	{ path: 'workflow', component: WorkflowComponent, canActivate: [AuthGuard] },
	{ path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
	{ path: 'dragtest', component: DragtestComponent, canActivate: [AuthGuard] }
];
