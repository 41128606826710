import { Component } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Broker } from '../../core/models/broker.model';
import { EmailRequest } from '../../core/models/emailrequest.model';
import { ParsedEmail } from '../../core/models/parsedemail.model';
import { SlimERPConnection } from '../../core/models/slimerpconnection.model';
import { Order } from '../../core/models/order.model';
import { OrderItem } from '../../core/models/order.model';
import { User } from '../../core/models/user.model';
import { BrokerService } from '../../core/services/broker.service'; 
import { ErpconnectionService } from '../../core/services/erpconnection.service'; 
import { EmailrequestService } from '../../core/services/emailrequest.service'; 
import { ErpsubmissionattemptService } from '../../core/services/erpsubmissionattempt.service';
import { BlobService } from '../../core/services/blob.service'; 
import { ParsedemailService } from '../../core/services/parsedemail.service'; 
import { UserService } from '../../core/services/user.service'; 
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { YesnoconfirmComponent } from '../../shared/components/yesnoconfirm/yesnoconfirm.component';
import { NaviconbarComponent } from '../../shared/components/naviconbar/naviconbar.component';
import { TruncatePipe } from '../../shared/pipes/truncate/truncate.pipe';

@Component({
  selector: 'app-parsedemails',
  standalone: true,
  imports: [RouterModule, CommonModule, MatMenuModule, MatNativeDateModule, MatDatepickerModule, MatDialogModule, MatTooltipModule, MatCheckboxModule, MatChipsModule, MatRadioModule, MatIconModule, MatSelectModule, MatButtonModule, MatBottomSheetModule, MatSidenavModule, MatTableModule, MatInputModule, FormsModule, NaviconbarComponent, TruncatePipe, DatePipe],
  templateUrl: './parsedemails.component.html',
  styleUrl: './parsedemails.component.scss'
})
export class ParsedemailsComponent {

	isBrokerLoading: boolean = false;	
	isLoading: boolean = false;

	displayedColumns: string[] = ['id', 'createDate', 'status', 'percentComplete', 'parentId', 'delButton'];
	dataSource: ParsedEmail[] = [];

	brokerDataSource: Broker[] = [];
	userDataSource: User[] = [];
	erpConnectionDataSource: SlimERPConnection[] = [];

	showFiller = false;
	showDismissed = false;

	chosenItem: number = 0;
	selectedOrderItem: OrderItem;

	selectedBroker: number = 0;
	selectedUser: string = '';
	searchSelection: number = 0;

	txtSender: string = "";
	txtRecipient: string = "";
	txtSubject: string = "";
	txtBody: string = "";

	disableERPButton: boolean = true;

	selectedParsedEmail: ParsedEmail = this.makeParsedEmailObj();
	selectedEmailRequest: EmailRequest = this.makeEmailRequestObj();

	constructor(private dataService: ParsedemailService,
	private brokerDataService: BrokerService,
	private blobService: BlobService,
	private erpSubmissionAttemptDataService: ErpsubmissionattemptService,
	private emailRequestDataService: EmailrequestService,
	private userDataService: UserService,
	private erpConnectionDataService: ErpconnectionService,
	private _snackBar: MatSnackBar,
	private yesnoConfirm: MatBottomSheet)
	{
		this.isBrokerLoading = true;
		this.brokerDataService.getBrokers().subscribe({
			next: x => 
			{
				this.isBrokerLoading = false;
				this.brokerDataSource = x;
				if(this.brokerDataSource.length == 1)
				{
					this.selectedBroker = this.brokerDataSource[0].id;
				}
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isBrokerLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Broker data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Broker data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Broker data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Broker data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Broker data: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});

		this.userDataService.getUsers().subscribe({
			next: x => 
			{
				this.userDataSource = x;
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching User data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching User data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching User data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching User data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching User data: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});

	}

	onIncludeDismissedChange(event: any)
	{
		this.showDismissed = event.selected;
	}

	openSnackBar(message: string, action: string)
	{
		this._snackBar.open(message, action,
		{
			horizontalPosition: 'center',
			verticalPosition: 'top',
			duration: 5000
		});
	}

	refreshItems()
	{
		// this is the Broker selection
		if(this.searchSelection == 1)
		{
			this.disableERPButton = false;
			this.refreshItemsByBrokerId(this.selectedBroker);
		}
		// this is the User selection
		else if(this.searchSelection == 2)
		{
			this.disableERPButton = true;
			this.refreshItemsByUserId(this.selectedUser);
		}
	}

	refreshItemsByBrokerId(brokerId: number)
	{
		this.isLoading = true;
		this.dataService.getParsedEmailsForBrokerId(brokerId, this.showDismissed).subscribe({
			next: x => 
			{
				this.dataSource = x;
				this.isLoading = false;
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Parsed Email data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Parsed Email data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Parsed Email data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Parsed Email data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Parsed Email data: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});

		this.erpConnectionDataService.getSlimERPConnectionsForBrokerId(brokerId).subscribe({
			next: x => 
			{
				this.erpConnectionDataSource = x;
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching ERP Connection data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching ERP Connection data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching ERP Connection data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching ERP Connection data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching ERP Connection data: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}

	refreshItemsByUserId(userId: string)
	{
		this.isLoading = true;	
		this.dataService.getParsedEmailsForUserId(userId, this.showDismissed).subscribe({
			next: x => 
			{
				this.dataSource = x;
				this.isLoading = false;
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Parsed Email data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Parsed Email data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Parsed Email data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Parsed Email data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Parsed Email data: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}

	rowClicked(drawer: any, row: ParsedEmail)
	{
		this.dataService.getParsedEmail(row.id).subscribe({
			next: x =>
			{
				this.selectedParsedEmail = x;
				console.log(x);
				this.emailRequestDataService.getEmailRequest(x.emailRequestId).subscribe({
					next: y =>
					{
						this.selectedEmailRequest = y;
					},
					error: (err2: HttpErrorResponse) =>
					{
						if (err2.error instanceof ErrorEvent) {
							// Client-side or network error
							this.openSnackBar("A client error occurred: " + err2.error.message, "Dismiss");
						} 
						else {
							// Backend error
							switch (err2.status) {
								case 400:
									this.openSnackBar("Error fetching Email Request associated with Parsed Email data: 400 Bad Request.", "Dismiss");
									break;
								case 401:
									this.openSnackBar("Error fetching Email Request associated with Parsed Email data: 401 Unauthorized.", "Dismiss");
									break;
								case 404:
									this.openSnackBar("Error fetching Email Request associated with Parsed Email data: 404 Not Found.", "Dismiss");
									break;
								case 500:
									this.openSnackBar("Error fetching Email Request associated with Parsed Email data: 500 Server Error", "Dismiss");
									break;
								default:
									this.openSnackBar("Error fetching Email Request associated with Parsed Email data: Unknown Error", "Dismiss");
									break;
							}
						}

						console.log(err2);
					}
				});
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Parsed Email data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Parsed Email data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Parsed Email data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Parsed Email data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Parsed Email data: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});

		drawer.open();
	}

	dismissItem(item: EmailRequest)
	{
		var conf = this.yesnoConfirm.open(YesnoconfirmComponent);
		conf.afterDismissed().subscribe(data =>
		{
			if(data)
			{
				// yes, go ahead and delete
				this.dataService.dismissParsedEmail(item.id).subscribe({
					next: x =>
					{
						this.openSnackBar("Parsed Email dismissed.  Id: " + item.id, "Dismiss");
						this.refreshItems();

					},
					error: (err: HttpErrorResponse) =>
					{
						if (err.error instanceof ErrorEvent) {
							// Client-side or network error
							this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
						} 
						else {
							// Backend error
							switch (err.status) {
								case 400:
									this.openSnackBar("Error dismissing Parsed Email: 400 Bad Request.", "Dismiss");
									break;
								case 401:
									this.openSnackBar("Error dismissing Parsed Email: 401 Unauthorized.", "Dismiss");
									break;
								case 404:
									this.openSnackBar("Error dismissing Parsed Email: 404 Not Found.", "Dismiss");
									break;
								case 500:
									this.openSnackBar("Error dismissing Parsed Email: 500 Server Error", "Dismiss");
									break;
								default:
									this.openSnackBar("Error dismissing Parsed Email: Unknown Error", "Dismiss");
									break;
							}
						}

						console.log(err);
					}
				});

			}
			else
			{
				// no, do not delete
			}
		});
	}

	closeClicked(drawer: any)
	{
		drawer.close();	
	}

	// create a "blank" instance of the ParsedEmail interface/obj
	makeParsedEmailObj() : ParsedEmail
	{
		let newItem: ParsedEmail =
		{
			id: 0,
			aIEngineUsed: 0,
			createDate: new Date(),
			orderObject: this.makeOrderObj(),
			errorNotes: null,
			status: 0,
			emailRequestId: 0,
			userId: 0,
			brokerId: 0,
			erpConnectionCustomerId: 0,
			isDismissed: false
		};
		return newItem;
	}

	makeOrderObj(): Order
	{
		let newItem: Order =
		{
			id: 0,
			gptQuestions: []
		};
		return newItem;
	}

	setChosenItem(id: number)
	{
		this.chosenItem = id;
	}

	makeEmailRequestObj() : EmailRequest 
	{
		let newItem: EmailRequest =
		{
			id: 0,
			createDate: new Date(),
			emailSender: '',
			emailRecipient: '',
			emailSubject: '',
			emailBody: '',
			brokerId: 0,
			userId: 0,
			isDismissed: false,
			eRPSubmissionAttemptId: 0,
			eRPConnectionCustomerId: 0,
			externalEmailId: 0
		};
		return newItem;
	}	

	openAttachment(drawer: any)
	{
		this.blobService.downloadBlobForEmailRequest(this.selectedParsedEmail.emailRequestId).subscribe({
			next: (response) => {

				// Extract the file name from the Content-Disposition header
				const contentDisposition = response.headers.get('content-disposition');
				let fileName = 'downloaded-file';

				if (contentDisposition) {
					// Prioritize filename* if present, else use filename
					const filenameRegex = /filename\*?=(?:(?:UTF-8'')?([^;]+)|"([^"]+)")/;
					const matches = filenameRegex.exec(contentDisposition);

					if (matches) {
					  // Choose whichever match is not undefined (i.e., prioritize filename*)
					  fileName = decodeURIComponent(matches[1] || matches[2]);
					}
				}

				const blob = response.body;
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.target = '_blank';
				a.rel = 'noopener';
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			},
			error: (error) => {
				this.openSnackBar("Error downloading file.", "Dismiss");
			}
		});


	}


}
