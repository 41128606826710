<div class="parent-frame">
	<mat-drawer-container class="drawer-container" autosize>
		<mat-drawer #drawer class="drawer-flyout" mode="over">

				<div class="splitview">
					<div class="leftsplit">
						<mat-form-field class="full-width-input">
							<mat-label>Email Sender</mat-label>
							<input matInput type="text" [(ngModel)]="selectedEmailRequest.emailSender">
						</mat-form-field>

						<mat-form-field class="full-width-input">
							<mat-label>Email Recipient</mat-label>
							<input matInput type="text" [(ngModel)]="selectedEmailRequest.emailRecipient">
						</mat-form-field>

						<mat-form-field class="full-width-input">
							<mat-label>Email Subject</mat-label>
							<input matInput type="text" [(ngModel)]="selectedEmailRequest.emailSubject">
						</mat-form-field>

						<mat-form-field class="full-width-input">
							<mat-label>Email Body</mat-label>
							<textarea matInput matTextareaAutosize matAutosizeMinRows=10 style="height:30vh" [(ngModel)]="selectedEmailRequest.emailBody"></textarea>
						</mat-form-field>

						@if(selectedEmailRequest.attachmentGuid)
						{
							<button mat-fab matTooltip="Open attachment" aria-label="Open attachment" (click)="openAttachment(drawer)" style="margin-left: 10px">
								<mat-icon>attach_file</mat-icon>
							</button>
						}

					</div>

					<div class="middlesplit">
						<mat-icon style="transform: scale(2)">double_arrow</mat-icon>
					</div>

					<div class="rightsplit">
						@if(selectedParsedEmail.orderObject) {

							<mat-form-field class="full-width-input">
								<mat-label>Order Type</mat-label>
								<mat-select [(ngModel)]="selectedParsedEmail.orderObject.orderType" name="isPickupLiftgate">
									<mat-option [value]="0">LTL Order</mat-option>
									<mat-option [value]="1">FTL Order</mat-option>
								</mat-select>
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Equipment Type</mat-label>
								<mat-select [(ngModel)]="selectedParsedEmail.orderObject.equipmentType" name="equipmentType">
									<mat-option [value]="11">Conestoga</mat-option>
									<mat-option [value]="20">Flatbed</mat-option>
									<mat-option [value]="32">Flatbed, Van, or Reefer</mat-option>
									<mat-option [value]="42">LTL</mat-option>
									<mat-option [value]="48">Power Only</mat-option>
									<mat-option [value]="50">Reefer</mat-option>
									<mat-option [value]="78">Van</mat-option>
									<mat-option [value]="45">Not Specified</mat-option>
								</mat-select>
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Pickup Date</mat-label>
									<input matInput [matDatepicker]="picker1" [(ngModel)]="selectedParsedEmail.orderObject.pickupDate" name="pickupDate">
									<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
								<mat-datepicker #picker1></mat-datepicker>
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Ready Time</mat-label>
								<input matInput type="time" [(ngModel)]="selectedParsedEmail.orderObject.readyTime" name="readyTime">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Close Time</mat-label>
								<input matInput type="time" [(ngModel)]="selectedParsedEmail.orderObject.closeTime" name="closeTime">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper Business Name</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmail.orderObject.shipperBusinessName" name="shipperBusinessName">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper Address</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmail.orderObject.shipperAddress" name="shipperAddress">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper City</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmail.orderObject.shipperCity" name="shipperCity">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper State</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmail.orderObject.shipperState" name="shipperState">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper Zip</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmail.orderObject.shipperZip" name="shipperZip">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper Phone</mat-label>
								<input matInput type="tel" [(ngModel)]="selectedParsedEmail.orderObject.shipperPhone" name="shipperPhone">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper Name</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmail.orderObject.shipperName" name="shipperName">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper Email</mat-label>
								<input matInput type="email" [(ngModel)]="selectedParsedEmail.orderObject.shipperEmail" name="shipperEmail">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Consignee Business Name</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmail.orderObject.consigneeBusinessName" name="consigneeBusinessName">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Consignee Address</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmail.orderObject.consigneeAddress" name="consigneeAddress">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Consignee City</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmail.orderObject.consigneeCity" name="consigneeCity">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Consignee State</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmail.orderObject.consigneeState" name="consigneeState">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Consignee Zip</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmail.orderObject.consigneeZip" name="consigneeZip">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Consignee Phone</mat-label>
								<input matInput type="tel" [(ngModel)]="selectedParsedEmail.orderObject.consigneePhone" name="consigneePhone">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Consignee Name</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmail.orderObject.consigneeName" name="consigneeName">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Consignee Email</mat-label>
								<input matInput type="email" [(ngModel)]="selectedParsedEmail.orderObject.consigneeEmail" name="consigneeEmail">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Pallet Count</mat-label>
								<input matInput type="number" [(ngModel)]="selectedParsedEmail.orderObject.palletCount" type="number" name="palletCount">
							</mat-form-field>


<!-- START SECTION ON ITEM DETAILS -->
							<div class="orderitemborder">
								<mat-form-field class="full-width-input">
									<mat-label>Cargo Items</mat-label>
									<mat-select [(ngModel)]="selectedOrderItem" name="cargoItem"> 
										@for (cargoItem of selectedParsedEmail.orderObject.orderItems; track cargoItem) {
											<mat-option [value]="cargoItem">{{cargoItem.productDescription}}</mat-option>
										}
									</mat-select>
								</mat-form-field>


								@if(selectedOrderItem != null) {
									<mat-form-field class="full-width-input">
										<mat-label>Piece Count</mat-label>
										<input matInput type="number" [(ngModel)]="selectedOrderItem.pieceCount" type="number" name="pieceCount">
									</mat-form-field>

									<mat-form-field class="full-width-input">
										<mat-label>Package Type</mat-label>
										<mat-select [(ngModel)]="selectedOrderItem.packageType" name="packageType">
											<mat-option [value]="1">None</mat-option>
											<mat-option [value]="2">Pallets</mat-option>
											<mat-option [value]="3">Boxes</mat-option>
											<mat-option [value]="4">Bundles</mat-option>
											<mat-option [value]="5">Cartons</mat-option>
											<mat-option [value]="6">Cases</mat-option>
											<mat-option [value]="7">Crates</mat-option>
											<mat-option [value]="8">Drums</mat-option>
											<mat-option [value]="9">Pails</mat-option>
											<mat-option [value]="10">Rolls</mat-option>
											<mat-option [value]="11">Stacks</mat-option>
											<mat-option [value]="12">Totes</mat-option>
											<mat-option [value]="13">Wheels</mat-option>
											<mat-option [value]="16">Pieces</mat-option>
											<mat-option [value]="17">Bags</mat-option>
											<mat-option [value]="18">Cylinders</mat-option>
											<mat-option [value]="19">Buckets</mat-option>
											<mat-option [value]="20">Skids</mat-option>
											<mat-option [value]="21">Packages</mat-option>
											<mat-option [value]="22">Units</mat-option>
											<mat-option [value]="23">Bins</mat-option>
											<mat-option [value]="24">Coils</mat-option>
											<mat-option [value]="25">Gallons</mat-option>
											<mat-option [value]="26">Containers</mat-option>
											<mat-option [value]="27">Loose Bags</mat-option>
											<mat-option [value]="28">Poles</mat-option>
											<mat-option [value]="29">Reels</mat-option>
											<mat-option [value]="30">Sheets</mat-option>
											<mat-option [value]="32">Gaylords</mat-option>
											<mat-option [value]="33">Loose</mat-option>
											<mat-option [value]="34">Mixed Pallets</mat-option>
											<mat-option [value]="35">Supersacks</mat-option>
											<mat-option [value]="36">Railcar</mat-option>
											<mat-option [value]="37">Tiles</mat-option>
											<mat-option [value]="38">Cans</mat-option>
											<mat-option [value]="39">Jerricans</mat-option>
											<mat-option [value]="40">Carts</mat-option>
											<mat-option [value]="41">Tanks</mat-option>
											<mat-option [value]="42">Tubes</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field class="full-width-input">
										<mat-label>Product Description</mat-label>
										<input matInput type="text" [(ngModel)]="selectedOrderItem.productDescription" name="productDescription">
									</mat-form-field>

									<mat-form-field class="half-width-input">
										<mat-label>Weight</mat-label>
										<input matInput type="number" [(ngModel)]="selectedOrderItem.weight" type="number" name="weight">
									</mat-form-field>

									<mat-form-field class="quarter-width-input" style="margin-left: 5px">
										<mat-label>Weight Units</mat-label>
										<mat-select [(ngModel)]="selectedOrderItem.weightUnits">
											<mat-option value="">Unknown</mat-option>
											<mat-option value="lb">lb</mat-option>
											<mat-option value="mg">mg</mat-option>
											<mat-option value="g">g</mat-option>
											<mat-option value="kg">kg</mat-option>
											<mat-option value="mt">mt</mat-option>
											<mat-option value="oz">oz</mat-option>
											<mat-option value="t">t</mat-option>
										</mat-select>
									</mat-form-field>	

									@if(selectedParsedEmail.orderObject.orderType == 0) {

										<mat-form-field class="half-width-input">
											<mat-label>Length</mat-label>
											<input matInput [(ngModel)]="selectedOrderItem.length" type="number" name="length">
										</mat-form-field>

										<mat-form-field class="quarter-width-input" style="margin-left: 5px">
											<mat-label>Length Units</mat-label>
											<mat-select [(ngModel)]="selectedOrderItem.dimensionUnits">
												<mat-option value="">Unknown</mat-option>
												<mat-option value="mm">mm</mat-option>
												<mat-option value="cm">cm</mat-option>
												<mat-option value="m">m</mat-option>
												<mat-option value="in">in</mat-option>
												<mat-option value="ft">ft</mat-option>
												<mat-option value="yd">yd</mat-option>
											</mat-select>
										</mat-form-field>	

										<mat-form-field class="half-width-input">
											<mat-label>Width</mat-label>
											<input matInput [(ngModel)]="selectedOrderItem.width" type="number" name="width">
										</mat-form-field>

										<mat-form-field class="quarter-width-input" style="margin-left: 5px">
											<mat-label>Width Units</mat-label>
											<mat-select [(ngModel)]="selectedOrderItem.dimensionUnits">
												<mat-option value="">Unknown</mat-option>
												<mat-option value="mm">mm</mat-option>
												<mat-option value="cm">cm</mat-option>
												<mat-option value="m">m</mat-option>
												<mat-option value="in">in</mat-option>
												<mat-option value="ft">ft</mat-option>
												<mat-option value="yd">yd</mat-option>
											</mat-select>
										</mat-form-field>	

										<mat-form-field class="half-width-input">
											<mat-label>Height</mat-label>
											<input matInput [(ngModel)]="selectedOrderItem.height" type="number" name="height">
										</mat-form-field>

										<mat-form-field class="quarter-width-input" style="margin-left: 5px">
											<mat-label>Height Units</mat-label>
											<mat-select [(ngModel)]="selectedOrderItem.dimensionUnits">
												<mat-option value="">Unknown</mat-option>
												<mat-option value="mm">mm</mat-option>
												<mat-option value="cm">cm</mat-option>
												<mat-option value="m">m</mat-option>
												<mat-option value="in">in</mat-option>
												<mat-option value="ft">ft</mat-option>
												<mat-option value="yd">yd</mat-option>
											</mat-select>
										</mat-form-field>	

										<mat-form-field class="full-width-input">
											<mat-label>Cargo Class</mat-label>
											<input matInput [(ngModel)]="selectedOrderItem.cargoClass" name="cargoClass">
										</mat-form-field>

										<mat-form-field class="full-width-input">
											<mat-label>NMFC Number</mat-label>
											<input matInput [(ngModel)]="selectedOrderItem.nmfcNumber" name="nmfcNumber">
										</mat-form-field>

										<mat-form-field class="half-width-input">
											<mat-label>PCF</mat-label>
											<input matInput [(ngModel)]="selectedOrderItem.pcf" type="number" name="pcf">
										</mat-form-field>

									}

								}
							</div>

<!-- END SECTION ON ITEM DETAILS -->

							<mat-form-field class="full-width-input">
								<mat-label>Special Notes</mat-label>
								<textarea matInput [(ngModel)]="selectedParsedEmail.orderObject.specialNotes" name="specialNotes"></textarea>
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper Number</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmail.orderObject.shipperNumber" name="shipperNumber">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>PO Number</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmail.orderObject.poNumber" name="poNumber">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>GL Code</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmail.orderObject.glCode" name="glCode">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipment Value</mat-label>
								<input matInput type="number" [(ngModel)]="selectedParsedEmail.orderObject.shipmentValue" name="shipmentValue">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipment Value Currency</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmail.orderObject.shipmentValueCCY" name="shipmentValueCCY">
							</mat-form-field>

							<mat-checkbox [(ngModel)]="selectedParsedEmail.orderObject.isPickupLiftgate">Is Pickup Liftgate</mat-checkbox>

							<mat-checkbox [(ngModel)]="selectedParsedEmail.orderObject.isDeliveryLiftgate">Is Delivery Liftgate</mat-checkbox>

							<mat-checkbox [(ngModel)]="selectedParsedEmail.orderObject.isPickupResidential">Is Pickup Residential</mat-checkbox>

							<mat-checkbox [(ngModel)]="selectedParsedEmail.orderObject.isDeliveryResidential">Is Delivery Residential</mat-checkbox>

							<mat-checkbox [(ngModel)]="selectedParsedEmail.orderObject.isPickupLimitedAccess">Is Pickup Limited Access</mat-checkbox>

							<mat-checkbox [(ngModel)]="selectedParsedEmail.orderObject.isDeliveryLimitedAccess">Is Delivery Limited Access</mat-checkbox>

							<mat-checkbox [(ngModel)]="selectedParsedEmail.orderObject.isDeliveryAppointment">Is Delivery Appointment</mat-checkbox>

							<!-- this is an FTL order -->
							@if(selectedParsedEmail.orderObject.orderType == 1) {

								<mat-form-field class="full-width-input">
									<mat-label>Receiving Ready Time</mat-label>
									<input matInput type="time" [(ngModel)]="selectedParsedEmail.orderObject.receivingReadyTime" name="receivingReadyTime">
								</mat-form-field>

								<mat-form-field class="full-width-input">
									<mat-label>Receiving Close Time</mat-label>
									<input matInput type="time" [(ngModel)]="selectedParsedEmail.orderObject.receivingCloseTime" name="receivingCloseTime">
								</mat-form-field>

								<mat-form-field class="full-width-input">
									<mat-label>Required Linear Feet</mat-label>
									<input matInput type="number" [(ngModel)]="selectedParsedEmail.orderObject.requiredLinearFeet" name="requiredLinearFeet">
								</mat-form-field>

							}

							<mat-form-field class="full-width-input">
								<mat-label>AI Questions</mat-label>
								<textarea matInput [(ngModel)]="selectedParsedEmail.orderObject.gptQuestions" name="gptQuestions"></textarea>
							</mat-form-field>
						}
					</div>
				</div>


<!-- END DETAILED EMAIL FLYOUT -->
			<button mat-flat-button color="warn" (click)="closeClicked(drawer)">Close</button>
		</mat-drawer>

		<div class="body-frame">
			<div class="nav-bar">
				<app-naviconbar></app-naviconbar>
			</div>

			<div class="nav-bar">
				<mat-radio-group [ngClass]="{ 'loading-animation' : isBrokerLoading }" aria-label="Broker or User Search" [(ngModel)]="searchSelection">
					<mat-radio-button value="1">
						<!-- broker combo box dropdown -->
						<mat-form-field>
							<mat-label>Broker</mat-label>
							<mat-select [(ngModel)]="selectedBroker" name="broker">
								@for (broker of brokerDataSource; track broker) {
									<mat-option [value]="broker.id">{{broker.name}}</mat-option>
								}
							</mat-select>
						</mat-form-field>
					</mat-radio-button>
					<mat-radio-button value="2">
						<!-- user combo box dropdown -->
						<mat-form-field>
							<mat-label>User</mat-label>
							<mat-select [(ngModel)]="selectedUser" name="user">
								@for (user of userDataSource; track user) {
									<mat-option [value]="user.user_id">{{user.name}}</mat-option>
								}
							</mat-select>
						</mat-form-field>
					</mat-radio-button>
				</mat-radio-group>

				<button mat-flat-button color="primary" (click)="refreshItems()">Refresh Parsed Email List</button>
				<mat-chip-option [selected]="showDismissed" (selectionChange)="onIncludeDismissedChange($event)" style="height: 50%">Include dismissed items</mat-chip-option>

			</div>

			<div>
				<table [ngClass]="{ 'loading-animation' : isLoading }"  mat-table [dataSource]="dataSource" class="mat-elevation-z8">

					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef> Id </th>
						<td mat-cell *matCellDef="let item"> {{item.id}} </td>
					</ng-container>

					<ng-container matColumnDef="createDate">
						<th mat-header-cell *matHeaderCellDef> Create Date </th>
						<td mat-cell *matCellDef="let item" [ngClass]="{'dismissed': item.isDismissed}"> {{item.createDate | date:'full'}} </td>
					</ng-container>

					<ng-container matColumnDef="status">
						<th mat-header-cell *matHeaderCellDef> Status </th>
						<td mat-cell *matCellDef="let item"> 
							@if(item.status == 0) {
								<mat-icon matTooltip="Not submitted to AI">pending</mat-icon>
							}
							@if(item.status == 1) {
								<mat-icon matTooltip="Submitted to AI, awaiting response">hourglass_top</mat-icon>
							}
							@if(item.status == 2) {
								<mat-icon matTooltip="Successfully parsed by AI" style="color: green">check_circle</mat-icon>
							}
							@if(item.status == 3) {
								<mat-icon matTooltip="AI parsing failed" style="color: red">error</mat-icon>
							}
						</td>
					</ng-container>

					<ng-container matColumnDef="percentComplete">
						<th mat-header-cell *matHeaderCellDef> Percent Parsed </th>
						<td mat-cell *matCellDef="let item">
							<div class="progress-container">
								<div class="progress-bar" [style.width.%]="item.percentComplete"></div>
								<span class="progress-text" style="color: white">{{item.percentComplete}}%</span>
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="parentId">
						<th mat-header-cell *matHeaderCellDef> Parent Id </th>
						<td mat-cell *matCellDef="let item"> {{item.parentParsedEmailId}} </td>
					</ng-container>

					<ng-container matColumnDef="delButton">
						<th mat-header-cell *matHeaderCellDef> </th>
						<td mat-cell *matCellDef="let item"> <button mat-flat-button color="warn" (click)="$event.stopPropagation();dismissItem(item)">Dismiss</button> </td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;" (click)="rowClicked(drawer, row)"></tr>

				</table>

			</div>
		</div>

	</mat-drawer-container>
</div>
