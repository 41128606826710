<div class="parent-frame">
	<mat-drawer-container class="drawer-container" autosize>
		<mat-drawer #drawer class="drawer-flyout" mode="side">
			<mat-form-field class="full-width-input">
				<mat-label>Connection Name</mat-label>
				<input matInput type="text" [(ngModel)]="txtName">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>Client Id</mat-label>
				<input matInput type="text" [(ngModel)]="txtClientId">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>Client Secret</mat-label>
				<input matInput type="text" [(ngModel)]="txtClientSecret">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>Base URL</mat-label>
				<input matInput type="text" [(ngModel)]="txtBaseURL">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>Broker</mat-label>
				<mat-select [(ngModel)]="selectedBroker" name="brokerpref">
					@for (brokerpref of brokerDataSource; track brokerpref) {
						<mat-option [value]="brokerpref.id">{{brokerpref.name}}</mat-option>
					}
				</mat-select>
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>ERP System</mat-label>
				<mat-select [(ngModel)]="selectedERPSystem" name="erpsystempref">
					@for (erpsystempref of erpSystemDataSource; track erpsystempref) {
						<mat-option [value]="erpsystempref.id">{{erpsystempref.systemName}}</mat-option>
					}
				</mat-select>
			</mat-form-field>

			<button mat-flat-button color="primary" (click)="saveNew()">Save New ERP Connection</button>
			<button mat-flat-button color="warn" (click)="cancelClicked(drawer)">Cancel</button>

		</mat-drawer>

		<div class="body-frame">
			<div class="nav-bar">
				<app-naviconbar></app-naviconbar>
			</div>

			<div class="nav-bar">
				@if(localAuth.isAdmin) {
					<button mat-flat-button color="primary" (click)="drawer.open()">Add ERP Connection</button>
				}
				<button mat-flat-button color="primary" (click)="refreshItems()">Refresh ERP Connection List</button>
			</div>

			<div>
				<table [ngClass]="{ 'loading-animation': isLoading }" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef> Id </th>
						<td mat-cell *matCellDef="let item"> {{item.id}} </td>
					</ng-container>

					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef> Name </th>
						<td mat-cell *matCellDef="let item"> {{item.name}} </td>
					</ng-container>

					<ng-container matColumnDef="brokerId">
						<th mat-header-cell *matHeaderCellDef> Broker Id </th>
						<td mat-cell *matCellDef="let item"> {{item.brokerId}} </td>
					</ng-container>

					<ng-container matColumnDef="clientId">
						<th mat-header-cell *matHeaderCellDef> Client Id </th>
						<td mat-cell *matCellDef="let item"> {{item.clientId}} </td>
					</ng-container>

					<ng-container matColumnDef="clientSecret">
						<th mat-header-cell *matHeaderCellDef> Client Secret </th>
						<td mat-cell *matCellDef="let item"> {{item.clientSecret}} </td>
					</ng-container>

					<ng-container matColumnDef="isDefault">
						<th mat-header-cell *matHeaderCellDef> IsDefault </th>
						<td mat-cell *matCellDef="let item"> {{item.isDefault}} </td>
					</ng-container>

					<ng-container matColumnDef="baseURL">
						<th mat-header-cell *matHeaderCellDef> Base URL </th>
						<td mat-cell *matCellDef="let item"> {{item.baseURL}} </td>
					</ng-container>

					<ng-container matColumnDef="refreshCustomersButton">
						<th mat-header-cell *matHeaderCellDef> </th>
						<td mat-cell *matCellDef="let item"> <button mat-flat-button color="primary" (click)="refreshCustomers(item)">Refresh Customer List From ERP</button> </td>
					</ng-container>

					<ng-container matColumnDef="refreshContactsButton">
						<th mat-header-cell *matHeaderCellDef> </th>
						<td mat-cell *matCellDef="let item"> <button mat-flat-button color="primary" (click)="refreshContacts(item)">Refresh Contacts List From ERP</button> </td>
					</ng-container>

					<ng-container matColumnDef="makeDefaultButton">
						<th mat-header-cell *matHeaderCellDef> </th>
						<td mat-cell *matCellDef="let item"> <button mat-flat-button color="primary" (click)="makeDefault(item)">Make Default</button> </td>
					</ng-container>

					<ng-container matColumnDef="delButton">
						<th mat-header-cell *matHeaderCellDef> </th>
						<td mat-cell *matCellDef="let item"> <button mat-flat-button color="warn" (click)="deleteItem(item)">Delete</button> </td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;" (click)="rowClicked(row)"></tr>

				</table>
			</div>
		</div>

	</mat-drawer-container>
</div>

