import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ERPConnectionCustomer } from '../models/erpconnectioncustomer.model';
import { ERPConnectionContact } from '../models/erpconnectioncontact.model';
import { ERPConnectionCustomerAddressBookRecord } from '../models/erpconnectioncustomeraddressbookrecord.model';
import { SlimERPConnection } from '../models/slimerpconnection.model';
import { UrlService } from './url.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ErpconnectioncustomerService {

	private svcUrl = '/ERPConnectionCustomers';

	constructor(private http: HttpClient, private url: UrlService, private auth: AuthenticationService) { }

	getERPConnectionCustomer(id: number): Observable<ERPConnectionCustomer> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<ERPConnectionCustomer>(this.url.getBaseURL() + this.svcUrl + '/' + id, { headers });
	}

	getERPConnectionCustomersByERPId(id: string): Observable<ERPConnectionCustomer> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<ERPConnectionCustomer>(this.url.getBaseURL() + this.svcUrl + '/ERPId/' + id, { headers });
	}

	getERPConnectionCustomersByERPConnectionId(id: number): Observable<ERPConnectionCustomer[]> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<ERPConnectionCustomer[]>(this.url.getBaseURL() + this.svcUrl + '/CustomersByERPConnectionId/' + id, { headers });
	}

	getERPConnectionContactsByERPConnectionCustomerId(id: number): Observable<ERPConnectionContact[]> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<ERPConnectionContact[]>(this.url.getBaseURL() + this.svcUrl + '/ContactsByERPConnectionCustomerId/' + id, { headers });
	}

	getAddressBookByERPConnectionCustomerId(id: number): Observable<ERPConnectionCustomerAddressBookRecord[]> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<ERPConnectionCustomerAddressBookRecord[]>(this.url.getBaseURL() + this.svcUrl + '/AddressBookByERPConnectionCustomerId/' + id, { headers });
	}

	getERPConnectionCustomersByBrokerId(id: number): Observable<ERPConnectionCustomer[]> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<ERPConnectionCustomer[]>(this.url.getBaseURL() + this.svcUrl + '/Broker/' + id, { headers });
	}

	updateEmailDomain(item: ERPConnectionCustomer): Observable<ERPConnectionCustomer> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<ERPConnectionCustomer>(this.url.getBaseURL() + this.svcUrl + '/UpdateEmailDomain', item, { headers });
	}

	refreshCustomers(id: number): Observable<ERPConnectionCustomer[]> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<ERPConnectionCustomer[]>(this.url.getBaseURL() + this.svcUrl + '/RefreshCustomers/' + id, {}, { headers });
	}

	refreshContacts(id: number): Observable<ERPConnectionContact[]> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<ERPConnectionContact[]>(this.url.getBaseURL() + this.svcUrl + '/RefreshContacts/' + id, {}, { headers });
	}

	refreshAddressBook(id: number): Observable<ERPConnectionCustomerAddressBookRecord[]> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<ERPConnectionCustomerAddressBookRecord[]>(this.url.getBaseURL() + this.svcUrl + '/RefreshAddressBook/' + id, {}, { headers });
	}

}
