import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-drop',
  standalone: true,
  imports: [],
  templateUrl: './drop.component.html',
  styleUrl: './drop.component.scss'
})
export class DropComponent {
	@HostListener("dragover", ["$event"]) onDragOver(event: any) {
		event.preventDefault();
	}

	@HostListener("drop", ["$event"]) onDrop(event: any): boolean {
		if(event.dataTransfer.items) {
			Array.from(event.dataTransfer.items).forEach((item: any) => {
				if(item.kind === "file") {
					this.file.emit(item.getAsFile());
				}
			});
	}
	return false;
	}

	@Output() file = new EventEmitter();

	constructor() { }

	ngOnInit(): void {



	}



}
