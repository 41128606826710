import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ERPSystem } from '../../core/models/erpsystem.model';
import { ErpsystemService } from '../../core/services/erpsystem.service'; 
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { YesnoconfirmComponent } from '../../shared/components/yesnoconfirm/yesnoconfirm.component';
import { NaviconbarComponent } from '../../shared/components/naviconbar/naviconbar.component';


@Component({
  selector: 'app-erpsystems',
  standalone: true,
  imports: [RouterModule, CommonModule, MatTooltipModule, MatIconModule, MatButtonModule, MatBottomSheetModule, MatSidenavModule, MatTableModule, MatInputModule, FormsModule, NaviconbarComponent],
  templateUrl: './erpsystems.component.html',
  styleUrl: './erpsystems.component.scss'
})
export class ErpsystemsComponent {

	isLoading: boolean = false;

	txtERPSystemName = "";
	txtContactPerson = "";
	txtContactEmail = "";
	txtContactPhone = "";
	txtSystemId = 0;

	displayedColumns: string[] = ['id', 'systemName', 'contactPerson', 'contactEmail', 'contactPhone', 'systemId', 'delButton'];
	dataSource: ERPSystem[] = [];

	showFiller = false;

	constructor(private dataService: ErpsystemService,
	private _snackBar: MatSnackBar,
	private yesnoConfirm: MatBottomSheet)
	{
		this.refreshItems();
	}

	openSnackBar(message: string, action: string)
	{
		this._snackBar.open(message, action,
		{
			horizontalPosition: 'center',
			verticalPosition: 'top',
			duration: 5000
		});
	}

	refreshItems()
	{
		this.isLoading = true;
		this.dataService.getERPSystems().subscribe({
			next: x => 
			{
				this.dataSource = x;
				this.isLoading = false;
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching ERP System data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching ERP System data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching ERP System data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching ERP System data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching ERP System data: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}


	rowClicked(row: ERPSystem)
	{

	}

	saveNew()
	{
		let newItem = this.makeERPSystemObj();
		newItem.systemName = this.txtERPSystemName;
		newItem.contactPerson = this.txtContactPerson;
		newItem.contactEmail = this.txtContactEmail;
		newItem.contactPhone = this.txtContactPhone;
		newItem.systemId = this.txtSystemId;

		this.dataService.createERPSystem(newItem).subscribe({
			next: x => 
			{
				this.openSnackBar("ERP System created successfully!", "Dismiss");
				this.txtERPSystemName = "";
				this.refreshItems();
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error creating ERP System: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error creating ERP System: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error creating ERP System: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error creating ERP System: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error creating ERP System: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}

	deleteItem(item: ERPSystem)
	{
		var conf = this.yesnoConfirm.open(YesnoconfirmComponent);
		conf.afterDismissed().subscribe(data =>
		{
			if(data)
			{
				// yes, go ahead and delete
			}
			else
			{
				// no, do not delete
			}
		});
	}

	cancelClicked(drawer: any)
	{
		this.txtERPSystemName = "";
		this.txtContactPerson = "";
		this.txtContactEmail = "";
		this.txtContactPhone = "";
		drawer.close();	
	}

	// create a "blank" instance of the ERP System interface/obj
	makeERPSystemObj() : ERPSystem 
	{
		let newItem: ERPSystem =
		{
			id: 0,
			systemName: '',
			contactPerson: '',
			contactPhone: '',
			contactEmail: '',
			systemId: 0
		};
		return newItem;
	}	



}
