import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AIEngine } from '../../core/models/aiengine.model';
import { AiengineService } from '../../core/services/aiengine.service'; 
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { YesnoconfirmComponent } from '../../shared/components/yesnoconfirm/yesnoconfirm.component';
import { NaviconbarComponent } from '../../shared/components/naviconbar/naviconbar.component';

@Component({
  selector: 'app-aiengines',
  standalone: true,
  imports: [RouterModule, CommonModule, MatTooltipModule, MatIconModule, MatButtonModule, MatBottomSheetModule, MatSidenavModule, MatTableModule, MatInputModule, FormsModule, NaviconbarComponent],
  templateUrl: './aiengines.component.html',
  styleUrl: './aiengines.component.scss'
})
export class AienginesComponent {

	isLoading: boolean = false;

	txtAIEngineName = "";
	txtEngineUniqueId: number = 0;
	txtDeploymentName = "";

	displayedColumns: string[] = ['id', 'name', 'engineUniqueId', 'deploymentName', 'delButton'];
	dataSource: AIEngine[] = [];

	showFiller = false;

	constructor(private dataService: AiengineService,
	private _snackBar: MatSnackBar,
	private yesnoConfirm: MatBottomSheet)
	{
		this.refreshItems();
	}

	openSnackBar(message: string, action: string)
	{
		this._snackBar.open(message, action,
		{
			horizontalPosition: 'center',
			verticalPosition: 'top',
			duration: 5000
		});
	}

	refreshItems()
	{
		this.isLoading = true;
		this.dataService.getAIEngines().subscribe({
			next: x => 
			{
				this.dataSource = x;
				this.isLoading = false;
			},
			error: (err: HttpErrorResponse)  =>
			{
				this.isLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching AI Engine data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching AI Engine data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching AI Engine data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching AI Engine data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching AI Engine data: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});
	}


	rowClicked(row: AIEngine)
	{

	}

	saveNew()
	{
		let newItem = this.makeAIEngineObj();
		newItem.name = this.txtAIEngineName;
		newItem.engineUniqueId = this.txtEngineUniqueId;
		newItem.deploymentName = this.txtDeploymentName;

		this.dataService.createAIEngine(newItem).subscribe({
			next: x => 
			{
				this.openSnackBar("AI Engine created successfully!", "Dismiss");
				this.txtAIEngineName = "";
				this.txtDeploymentName = "";
				this.refreshItems();
			},
			error: (err: HttpErrorResponse)  =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error creating AI Engine: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error creating AI Engine: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error creating AI Engine: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error creating AI Engine: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error creating AI Engine: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});
	}

	deleteItem(item: AIEngine)
	{
		var conf = this.yesnoConfirm.open(YesnoconfirmComponent);
		conf.afterDismissed().subscribe(data =>
		{
			if(data)
			{
				// yes, go ahead and delete
			}
			else
			{
				// no, do not delete
			}
		});
	}

	cancelClicked(drawer: any)
	{
		this.txtAIEngineName = "";
		this.txtDeploymentName = "";
		drawer.close();	
	}

	// create a "blank" instance of the AIEngine interface/obj
	makeAIEngineObj() : AIEngine
	{
		let newItem: AIEngine =
		{
			id: 0,
			name: '',
			engineUniqueId: 0,
			deploymentName: ''
		};
		return newItem;
	}	

}
