import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Broker } from '../../core/models/broker.model';
import { ERPSystem } from '../../core/models/erpsystem.model';
import { ErpsystemService } from '../../core/services/erpsystem.service'; 
import { ERPConnection } from '../../core/models/erpconnection.model';
import { BrokerService } from '../../core/services/broker.service'; 
import { AuthenticationService } from '../../core/services/authentication.service';
import { ErpconnectionService } from '../../core/services/erpconnection.service'; 
import { ErpconnectioncustomerService } from '../../core/services/erpconnectioncustomer.service'; 
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSelectModule } from '@angular/material/select';
import { YesnoconfirmComponent } from '../../shared/components/yesnoconfirm/yesnoconfirm.component';
import { NaviconbarComponent } from '../../shared/components/naviconbar/naviconbar.component';

@Component({
  selector: 'app-erpconnections',
  standalone: true,
  imports: [RouterModule, CommonModule, MatTooltipModule, MatIconModule, MatSelectModule, MatButtonModule, MatBottomSheetModule, MatSidenavModule, MatTableModule, MatInputModule, FormsModule, NaviconbarComponent],
  templateUrl: './erpconnections.component.html',
  styleUrl: './erpconnections.component.scss'
})
export class ErpconnectionsComponent {

	isLoading: boolean = false;
	
	txtName = "";
	txtClientId = "";
	txtClientSecret = "";
	txtBaseURL = "";

	selectedBroker: number = 0;
	selectedERPSystem: number = 0;

	displayedColumns: string[] = ['id', 'name', 'brokerId', 'clientId', 'clientSecret', 'isDefault', 'baseURL', 'refreshCustomersButton', 'refreshContactsButton', 'makeDefaultButton', 'delButton'];
	dataSource: ERPConnection[] = [];

	brokerDataSource: Broker[] = [];
	erpSystemDataSource: ERPSystem[] = [];

	showFiller = false;

	constructor(private dataService: ErpconnectionService,
	private erpConnectionCustomerDataService: ErpconnectioncustomerService,
	private brokerDataService: BrokerService,
	private erpSystemDataService: ErpsystemService,
	public localAuth: AuthenticationService,
	private _snackBar: MatSnackBar,
	private yesnoConfirm: MatBottomSheet)
	{
		this.refreshItems();

		this.brokerDataService.getBrokers().subscribe({
			next: x => 
			{
				this.brokerDataSource = x;
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Broker data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Broker data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Broker data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Broker data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Broker data: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});

		this.erpSystemDataService.getERPSystems().subscribe({
			next: x => 
			{
				this.erpSystemDataSource = x;
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching ERP System data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching ERP System data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching ERP System data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching ERP System data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching ERP System data: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});

	}

	openSnackBar(message: string, action: string)
	{
		this._snackBar.open(message, action,
		{
			horizontalPosition: 'center',
			verticalPosition: 'top',
			duration: 5000
		});
	}

	refreshItems()
	{
		this.isLoading = true;
		this.dataService.getERPConnections().subscribe({
			next: x => 
			{
				this.dataSource = x;
				this.isLoading = false;
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching ERP Connection data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching ERP Connection data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching ERP Connection data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching ERP Connection data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching ERP Connection data: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});
	}


	rowClicked(row: ERPConnection)
	{

	}

	saveNew()
	{
		let newItem = this.makeERPConnectionObj();

		newItem.name = this.txtName;
		newItem.clientId = this.txtClientId;
		newItem.clientSecret = this.txtClientSecret;
		newItem.baseURL = this.txtBaseURL;
		newItem.brokerId = this.selectedBroker;
		newItem.eRPSystemId = this.selectedERPSystem;
		newItem.isDefault = false;

		this.dataService.createERPConnection(newItem).subscribe({
			next: x => 
			{
				this.openSnackBar("ERP Connection created successfully!", "Dismiss");
				this.txtName = "";
				this.refreshItems();
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error creating ERP Connection: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error creating ERP Connection: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error creating ERP Connection: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error creating ERP Connection: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error creating ERP Connection: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});
	}

	deleteItem(item: ERPConnection)
	{
		var conf = this.yesnoConfirm.open(YesnoconfirmComponent);
		conf.afterDismissed().subscribe(data =>
		{
			if(data)
			{
				// yes, go ahead and delete
			}
			else
			{
				// no, do not delete
			}
		});
	}

	refreshCustomers(item: ERPConnection)
	{
		this.erpConnectionCustomerDataService.refreshCustomers(item.id).subscribe({
			next: x => 
			{
				let qty = x.length;
				this.openSnackBar("Successfully refreshed " + qty + " items from ERP customer list!", "Dismiss");
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error refreshing ERP customer list: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error refreshing ERP customer list: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error refreshing ERP customer list: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error refreshing ERP customer list: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error refreshing ERP customer list: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});
	}

	refreshContacts(item: ERPConnection)
	{
		this.erpConnectionCustomerDataService.refreshContacts(item.id).subscribe({
			next: x => 
			{
				let qty = x.length;
				this.openSnackBar("Successfully refreshed " + qty + " items from ERP contacts list!", "Dismiss");
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error refreshing ERP contacts list: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error refreshing ERP contacts list: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error refreshing ERP contacts list: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error refreshing ERP contacts list: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error refreshing ERP contacts list: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});
	}

	makeDefault(item: ERPConnection)
	{
		this.dataService.setERPConnectionAsDefault(item.id).subscribe({
			next: x => 
			{
				this.openSnackBar("ERP Connection successfully set as default.", "Dismiss");
				this.refreshItems();
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error setting ERP Connection as default: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error setting ERP Connection as default: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error setting ERP Connection as default: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error setting ERP Connection as default: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error setting ERP Connection as default: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});
	}

	cancelClicked(drawer: any)
	{
		this.txtName = "";
		this.txtClientSecret = "";
		this.txtClientId = "";
		this.txtBaseURL = "";
		this.selectedBroker = 0;
		this.selectedERPSystem = 0;

		drawer.close();	
	}

	// create a "blank" instance of the ERPConnection interface/obj
	makeERPConnectionObj() : ERPConnection 
	{
		let newItem: ERPConnection =
		{
			id: 0,
			name: '',
			clientSecret: '',
			clientId: '',
			baseURL: '',
			brokerId: 0,
			eRPSystemId: 0,
			isDefault: false
		};
		return newItem;
	}	
}
