import { Component, NgZone } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { Broker } from '../../core/models/broker.model';
import { ERPConnectionCustomer } from '../../core/models/erpconnectioncustomer.model';
import { EmailRequest } from '../../core/models/emailrequest.model';
import { User } from '../../core/models/user.model';
import { BrokerService } from '../../core/services/broker.service'; 
import { EmailrequestService } from '../../core/services/emailrequest.service'; 
import { ErpconnectioncustomerService } from '../../core/services/erpconnectioncustomer.service'; 
import { ParsedemailService } from '../../core/services/parsedemail.service'; 
import { UserService } from '../../core/services/user.service'; 
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSelectModule } from '@angular/material/select';
import { YesnoconfirmComponent } from '../../shared/components/yesnoconfirm/yesnoconfirm.component';
import { NaviconbarComponent } from '../../shared/components/naviconbar/naviconbar.component';
import { TruncatePipe } from '../../shared/pipes/truncate/truncate.pipe';
import parse from 'emailjs-mime-parser';
import * as MsgReader from '@sharpenednoodles/msg.reader-ts';
import { MSGFileData } from '@sharpenednoodles/msg.reader-ts';

interface EmailData {
	sender: string;
	recipient: string;
	subject: string;
	body: string;
}

interface MimeNode {
	headers: {
		'content-type': {value: string}[]
	}
	charset?: string;
	content?: any;
	childNodes: MimeNode[];
}

@Component({
  selector: 'app-emailrequests',
  standalone: true,
  imports: [RouterModule, CommonModule, MatTooltipModule, MatRadioModule, MatIconModule, MatChipsModule, MatSelectModule, MatButtonModule, MatBottomSheetModule, MatSidenavModule, MatTableModule, MatInputModule, FormsModule, NaviconbarComponent, TruncatePipe, DatePipe],
  templateUrl: './emailrequests.component.html',
  styleUrl: './emailrequests.component.scss'
})
export class EmailrequestsComponent {

	isBrokerLoading: boolean = false;
	isLoading: boolean = false;

	displayedColumns: string[] = ['id', 'createDate', 'emailSender', 'emailSubject', 'emailBody', 'emailAttachment', 'delButton'];
	dataSource: EmailRequest[] = [];

	brokerDataSource: Broker[] = [];
	userDataSource: User[] = [];

	erpConnectionCustomerDataSource: ERPConnectionCustomer[] = [];

	showFiller = false;
	showDismissed = false;

	public list: any[] = [];

	selectedBroker: number = 0;
	selectedUser: string = '';
	searchSelection: number = 0;

	constructor(private dataService: EmailrequestService,
	private brokerDataService: BrokerService,
	private erpConnectionCustomerDataService: ErpconnectioncustomerService,
	private parsedEmailDataService: ParsedemailService,
	private userDataService: UserService,
	private _snackBar: MatSnackBar,
	private ngZone: NgZone,
	private yesnoConfirm: MatBottomSheet)
	{
		this.isBrokerLoading = true;
		this.brokerDataService.getBrokers().subscribe({
			next: x => 
			{
				this.isBrokerLoading = false;
				this.brokerDataSource = x;
				if(this.brokerDataSource.length == 1)
				{
					this.selectedBroker = this.brokerDataSource[0].id;
				}
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isBrokerLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Broker data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Broker data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Broker data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Broker data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Broker data: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});

		this.userDataService.getUsers().subscribe({
			next: x => 
			{
				this.userDataSource = x;
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching User data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching User data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching User data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching User data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching User data: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});

	}

	onIncludeDismissedChange(event: any)
	{
		this.showDismissed = event.selected;
	}

	openSnackBar(message: string, action: string)
	{
		this._snackBar.open(message, action,
		{
			horizontalPosition: 'center',
			verticalPosition: 'top',
			duration: 5000
		});
	}

	refreshItems()
	{
		// this is the Broker selection
		if(this.searchSelection == 1)
		{
			this.refreshItemsByBrokerId(this.selectedBroker);
		}
		// this is the User selection
		else if(this.searchSelection == 2)
		{
			console.log(this.selectedUser);
			console.log(this.userDataSource);
			this.refreshItemsByUserId(this.selectedUser);
		}
	}

	refreshItemsByBrokerId(brokerId: number)
	{
		this.isLoading = true;
		this.dataService.getEmailRequestsForBrokerId(brokerId, this.showDismissed).subscribe({
			next: x => 
			{
				this.dataSource = x;
				this.isLoading = false;
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Email Request data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Email Request data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Email Request data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Email Request data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Email Request data: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});

		this.erpConnectionCustomerDataService.getERPConnectionCustomersByBrokerId(brokerId).subscribe({
			next: x => 
			{
				this.erpConnectionCustomerDataSource = x;
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching ERP Connection Customer data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching ERP Connection Customer data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching ERP Connection Customer data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching ERP Connection Customer data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching ERP Connection Customer data: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});

	}

	refreshItemsByUserId(userId: string)
	{
		this.isLoading = true;
		this.dataService.getEmailRequestsForUserId(userId, this.showDismissed).subscribe({
			next: x => 
			{
				this.dataSource = x;
				this.isLoading = false;
			},
			error: (err: HttpErrorResponse)  =>
			{
				this.isLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Email Request data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Email Request data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Email Request data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Email Request data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Email Request data: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});
	}

	dismissItem(item: EmailRequest)
	{
		var conf = this.yesnoConfirm.open(YesnoconfirmComponent);
		conf.afterDismissed().subscribe(data =>
		{
			if(data)
			{
				// yes, go ahead and delete
				this.dataService.dismissEmailRequest(item.id).subscribe({
					next: x => 
					{
						this.openSnackBar("Email Request dismissed.  Id: " + item.id, "Dismiss");
						this.refreshItems();	
					},
					error: (err: HttpErrorResponse) =>
					{
						if (err.error instanceof ErrorEvent) {
							// Client-side or network error
							this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
						} 
						else {
							// Backend error
							switch (err.status) {
								case 400:
									this.openSnackBar("Error dismissing Email Request: 400 Bad Request.", "Dismiss");
									break;
								case 401:
									this.openSnackBar("Error dismissing Email Request: 401 Unauthorized.", "Dismiss");
									break;
								case 404:
									this.openSnackBar("Error dismissing Email Request: 404 Not Found.", "Dismiss");
									break;
								case 500:
									this.openSnackBar("Error dismissing Email Request: 500 Server Error", "Dismiss");
									break;
								default:
									this.openSnackBar("Error dismissing Email Request: Unknown Error", "Dismiss");
									break;
							}
						}
						console.log(err);
					}
				});


			}
			else
			{
				// no, do not delete
			}
		});
	}

	cancelClicked(drawer: any)
	{
		drawer.close();	
	}
}
