import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { UrlService } from './url.service';
import { AuthService } from '@auth0/auth0-angular';
import { jwtDecode } from 'jwt-decode';
import { HttpClient, HttpHeaders } from '@angular/common/http';

interface TokenPayload {
	[key: string]: any;
}

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

	bearerToken: string = "";
	idToken: string = "";

	token: any;

	isAdmin: boolean = false;

	roles: string[];

	constructor(private http: HttpClient, private url: UrlService,  private auth: AuthService) { 
		this.auth.user$.subscribe(user => {
			// could do something with the user if we want to?
		});

		this.auth.isAuthenticated$.subscribe(myauth => {
			this.auth.getAccessTokenSilently({ detailedResponse: true }).subscribe(x => {
				this.token = x;
				this.bearerToken = x.access_token;
				this.idToken = x.id_token;

				this.roles = this.getRolesFromToken(x.access_token);
				if(this.hasAdminRole(this.roles))
				{
					this.isAdmin = true;
				}
			});
		});
	}

	getRolesFromToken(token: string): string[] {
		try {
			const decodedToken: TokenPayload = jwtDecode(token);
			return decodedToken['dev/roles'] || [];
		} catch (error) {
			console.error('Error decoding token:', error);
			return [];
		}
	}

	hasAdminRole(roles: string[]): boolean {
		return roles.some(role => role.toUpperCase() === 'ADMIN');
	}

	getBearerToken() {
		if(this.bearerToken == "") {
			return null;
		}
		else {
			return this.bearerToken;
		}
	}

	resetPassword() {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.getBearerToken());
		return this.http.post(this.url.getBaseURL() + '/Users/ResetPassword', '', { headers });
	}
}
