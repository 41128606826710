import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '@auth0/auth0-angular';
import { AuthenticationService } from '../../core/services/authentication.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [RouterModule, MatTooltipModule, MatButtonModule, MatIconModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

	subscription: any;
	user: any;

	constructor(@Inject(DOCUMENT) public document: Document, private auth: AuthService, public localAuth: AuthenticationService, private _snackBar: MatSnackBar) {

	}

	openSnackBar(message: string, action: string)
	{
		this._snackBar.open(message, action,
		{
			horizontalPosition: 'center',
			verticalPosition: 'top',
			duration: 5000
		});
	}

	ngOnInit() {
		this.subscription = this.auth.user$.subscribe(user => {
			this.user = user;
			console.log('User data:', user);
		});

		this.subscription = this.auth.isAuthenticated$.subscribe(myauth => {
			console.log('IsAuth data:', myauth);
		});

	}

	login() {
		this.auth.loginWithRedirect();
	}

	logout() {
		this.auth.logout({
			logoutParams: {
				returnTo: this.document.location.origin
			}
		});
	}

	async copyBearerToken() {

		try {
			await navigator.clipboard.writeText(this.localAuth.bearerToken);
			this.openSnackBar("Bearer token copied to clipboard", "Dismiss");
		} catch (err) {
			this.openSnackBar("Error: could not copy Bearer token to clipboard", "Dismiss");
		}

	}

	async copyIdToken() {

		try {
			await navigator.clipboard.writeText(this.localAuth.idToken);
			this.openSnackBar("Id token copied to clipboard", "Dismiss");
		} catch (err) {
			this.openSnackBar("Error: could not copy Id token to clipboard", "Dismiss");
		}

	}

	resetPassword() {
		this.localAuth.resetPassword().subscribe({
			next: x => 
			{
				this.openSnackBar("Password reset email sent!", "Dismiss");
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error requesting password reset: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error requesting password reset: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error requesting password reset: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error requesting password reset: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error requesting password reset: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});
	}

}
