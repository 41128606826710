import { Component, Injectable } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DropComponent } from '../../shared/components/drop/drop.component';
import parse from 'emailjs-mime-parser';
import * as MsgReader from '@sharpenednoodles/msg.reader-ts';
import { MSGFileData } from '@sharpenednoodles/msg.reader-ts';
import { Observable, of } from 'rxjs';

interface MimeNode {
	headers: {
		'content-type': {value: string}[]
	}
	charset?: string;
	content?: any;
	childNodes: MimeNode[];
}

@Component({
  selector: 'app-dragtest',
  standalone: true,
  imports: [RouterOutlet, DropComponent],
  templateUrl: './dragtest.component.html',
  styleUrl: './dragtest.component.scss'
})
export class DragtestComponent {

	title = "eml-parser";

	public list: any[] = [];

/*
	loadFile(file) {
		console.log(file);

		const reader = new FileReader();

		const getContent = (mimenode: MimeNode): string[] => {
			const emailContent: string[] = [];
			const _getContent = (childNodes: MimeNode[]) => {
				childNodes.forEach(child => {
					if(child.childNodes.length) {
						_getContent(child.childNodes);
					} else if (child.content && child.charset === 'utf-8' && child.headers['content-type'][0].value === 'text/html') {
						emailContent.push(new TextDecoder().decode(child.content));
					} else if (child.content && child.headers['content-type'][0].value === 'text/plain') {
						emailContent.push(new TextDecoder().decode(child.content));
					}	
				});
			}
			_getContent([mimenode]);
			return emailContent;
		}

		reader.addEventListener("load", () => {
			const parsed = parse(reader.result);
			console.log("parsed email: ", parsed);
			this.list = [{
				...parsed.headers,
				body: getContent(parsed).join()
			}, ...this.list];
		
		}, false);

		reader.readAsText(file);
	}
*/

	loadFile(file) {
		let results = file.name.split('.');
		if(results[results.length - 1].toUpperCase() == "MSG") {
			console.log("this is a msg file");
		}
		else if (results[results.length - 1].toUpperCase() == "EML") {
			console.log("this is a eml file");
		} else {
			console.log("this is a file type i dont know");
		}

		console.log("fired");
		console.log(file);

const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(file);

      fileReader.addEventListener("load", (e: ProgressEvent): void => {
		console.log("File loaded");
        let bytes = new Uint8Array((<any>e.target).result);

        const msgReader = new MsgReader.MSGReader(bytes);
        const msg = msgReader.getFileData();
		console.log(msg);

        if (msg['error']) { console.log(msg["error"]); }

      });

      fileReader.onerror = (error: ProgressEvent): void => {
			console.log(error);
      };


	}



}
