import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlService } from './url.service';
import { AuthenticationService } from './authentication.service';


@Injectable({
  providedIn: 'root'
})
export class BlobService {

	private svcUrl = '/Blobs';

	constructor(private http: HttpClient, private url: UrlService, private auth: AuthenticationService) { 

	}

	downloadBlobForEmailRequest(id: number)
	{
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get(this.url.getBaseURL() + this.svcUrl + '/DownloadEmailRequestAttachment/' + id, { headers, responseType: 'blob', observe: 'response' });
	}

}
