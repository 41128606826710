import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Broker } from '../../core/models/broker.model';
import { AIEngine } from '../../core/models/aiengine.model';
import { BrokerService } from '../../core/services/broker.service'; 
import { AiengineService } from '../../core/services/aiengine.service'; 
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSelectModule } from '@angular/material/select';
import { YesnoconfirmComponent } from '../../shared/components/yesnoconfirm/yesnoconfirm.component';
import { NaviconbarComponent } from '../../shared/components/naviconbar/naviconbar.component';

@Component({
  selector: 'app-brokers',
  standalone: true,
  imports: [RouterModule, CommonModule, MatTooltipModule, MatIconModule, MatSelectModule, MatButtonModule, MatBottomSheetModule, MatSidenavModule, MatTableModule, MatInputModule, FormsModule, NaviconbarComponent],
  templateUrl: './brokers.component.html',
  styleUrl: './brokers.component.scss'
})
export class BrokersComponent {

	isLoading: boolean = false;

	txtBrokerName = "";	
	txtDBA = "";
	txtAddress = "";
	txtCity = "";	
	txtState = "";	
	txtZip = "";
	txtPhone = "";
	txtContactPerson = "";
	txtContactEmail = "";
	txtContactPhone = "";
	txtDefaultRecipientEmail = "";
	txtEmailDomain = "";

	selectedAI: number = 0;

	displayedColumns: string[] = ['id', 'name', 'city', 'state', 'contactPerson', 'defaultRecipientEmail', 'delButton'];
	dataSource: Broker[] = [];

	AIEngineDataSource: AIEngine[] = [];

	showFiller = false;

	constructor(private dataService: BrokerService,
	private AIdataService: AiengineService,
	private _snackBar: MatSnackBar,
	private yesnoConfirm: MatBottomSheet)
	{
		this.refreshItems();

		this.AIdataService.getAIEngines().subscribe({
			next: x => 
			{
				this.AIEngineDataSource = x;
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching AI Engine data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching AI Engine data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching AI Engine data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching AI Engine data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching AI Engine data: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});


	}

	openSnackBar(message: string, action: string)
	{
		this._snackBar.open(message, action,
		{
			horizontalPosition: 'center',
			verticalPosition: 'top',
			duration: 5000
		});
	}

	refreshItems()
	{
		this.isLoading = true;
		this.dataService.getBrokers().subscribe({
			next: x => 
			{
				this.dataSource = x;
				this.isLoading = false;
			},
			error: (err: HttpErrorResponse) =>
			{	
				this.isLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Broker data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Broker data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Broker data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Broker data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Broker data: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});
	}


	rowClicked(row: Broker)
	{

	}

	saveNew()
	{
		let newItem = this.makeBrokerObj();
		newItem.name = this.txtBrokerName;
		newItem.dba = this.txtDBA;
		newItem.address = this.txtAddress;
		newItem.city = this.txtCity;
		newItem.state = this.txtState;
		newItem.zipCode = this.txtZip;
		newItem.phone = this.txtPhone;
		newItem.contactPerson = this.txtContactPerson;
		newItem.contactEmail = this.txtContactEmail;
		newItem.contactPhone = this.txtContactPhone;
		newItem.defaultRecipientEmail = this.txtDefaultRecipientEmail;
		newItem.emailDomain = this.txtEmailDomain;
		newItem.aiEngineId = this.selectedAI;

		this.dataService.createBroker(newItem).subscribe({
			next: x => 
			{
				this.openSnackBar("Broker created successfully!", "Dismiss");
				this.txtBrokerName = "";
				this.refreshItems();
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error creating Broker: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error creating Broker: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error creating Broker: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error creating Broker: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error creating Broker: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});
	}

	deleteItem(item: Broker)
	{
		var conf = this.yesnoConfirm.open(YesnoconfirmComponent);
		conf.afterDismissed().subscribe(data =>
		{
			if(data)
			{
				// yes, go ahead and delete
			}
			else
			{
				// no, do not delete
			}
		});
	}

	cancelClicked(drawer: any)
	{
		this.txtBrokerName = "";
		this.txtDBA = "";
		this.txtAddress = "";
		this.txtCity = "";	
		this.txtState = "";	
		this.txtZip = "";
		this.txtPhone = "";
		this.txtContactPerson = "";
		this.txtContactEmail = "";
		this.txtContactPhone = "";
		this.txtDefaultRecipientEmail = "";
		this.txtEmailDomain = "";
		this.selectedAI = 0;

		drawer.close();	
	}

	// create a "blank" instance of the Broker interface/obj
	makeBrokerObj() : Broker 
	{
		let newItem: Broker =
		{
			id: 0,
			name: '',
			dba: '',
			address: '',
			city: '',
			state: '',
			zipCode: '',
			phone: '',
			contactPerson: '',
			contactPhone: '',
			contactEmail: '',
			defaultRecipientEmail: '',
			emailDomain: '',
			aiEngineId: 0
		};
		return newItem;
	}	



}
