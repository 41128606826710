<div class="parent-frame">
	<mat-drawer-container class="drawer-container" autosize>
		<mat-drawer #drawer class="drawer-flyout" mode="over">
			<button mat-flat-button color="warn" (click)="closeClicked(drawer)">Close</button>
		</mat-drawer>

		<div class="body-frame">
			<div class="nav-bar">
				<app-naviconbar></app-naviconbar>
			</div>

			<div class="nav-bar">
				<mat-radio-group [ngClass]="{ 'loading-animation': isBrokerLoading }" aria-label="Broker or User Search" [(ngModel)]="searchSelection">
					<mat-radio-button value="1">
						<!-- broker combo box dropdown -->
						<mat-form-field>
							<mat-label>Broker</mat-label>
							<mat-select [(ngModel)]="selectedBroker" name="broker">
								@for (broker of brokerDataSource; track broker) {
									<mat-option [value]="broker.id">{{broker.name}}</mat-option>
								}
							</mat-select>
						</mat-form-field>
					</mat-radio-button>
					<mat-radio-button value="2">
						<!-- user combo box dropdown -->
						<mat-form-field>
							<mat-label>User</mat-label>
							<mat-select [(ngModel)]="selectedUser" name="user">
								@for (user of userDataSource; track user) {
									<mat-option [value]="user.user_id">{{user.name}}</mat-option>
								}
							</mat-select>
						</mat-form-field>
					</mat-radio-button>
				</mat-radio-group>

				<button mat-flat-button color="primary" (click)="refreshItems()">Refresh ERP Submission Attempts</button>
				<mat-chip-option [selected]="showDismissed" (selectionChange)="onIncludeDismissedChange($event)" style="height: 50%">Include dismissed items</mat-chip-option>

			</div>

			<div>
				<table [ngClass]="{ 'loading-animation' : isLoading }" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef> Id </th>
						<td mat-cell *matCellDef="let item"> {{item.id}} </td>
					</ng-container>

					<ng-container matColumnDef="createDate">
						<th mat-header-cell *matHeaderCellDef> Create Date </th>
						<td mat-cell *matCellDef="let item" [ngClass]="{'dismissed': item.isDismissed}"> {{item.createDate | date:'full'}} </td>
					</ng-container>

					<ng-container matColumnDef="lastUpdateDate">
						<th mat-header-cell *matHeaderCellDef> Last Update Date </th>
						<td mat-cell *matCellDef="let item" [ngClass]="{'dismissed': item.isDismissed}"> {{item.lastUpdateDate | date:'full'}} </td>
					</ng-container>

					<ng-container matColumnDef="parsedEmailId">
						<th mat-header-cell *matHeaderCellDef> Parsed Email Id </th>
						<td mat-cell *matCellDef="let item"> {{item.parsedEmailId}} </td>
					</ng-container>

					<ng-container matColumnDef="brokerId">
						<th mat-header-cell *matHeaderCellDef> Broker Id </th>
						<td mat-cell *matCellDef="let item"> {{item.brokerId}} </td>
					</ng-container>

					<ng-container matColumnDef="userId">
						<th mat-header-cell *matHeaderCellDef> User Id </th>
						<td mat-cell *matCellDef="let item"> {{item.userId}} </td>
					</ng-container>

					<ng-container matColumnDef="status">
						<th mat-header-cell *matHeaderCellDef> Status </th>
						<td mat-cell *matCellDef="let item">
							@if(item.status == 0) {
								<mat-icon matTooltip="Not submitted to ERP">pending</mat-icon>
							}
							@if(item.status == 1) {
								<mat-icon matTooltip="Submitted to ERP, awaiting response">hourglass_top</mat-icon>
							}
							@if(item.status == 2) {
								<mat-icon matTooltip="Successfully sent to ERP" style="color: green">check_circle</mat-icon>
							}
							@if(item.status == 3) {
								<mat-icon matTooltip="ERP submission failed" style="color: red">error</mat-icon>
							}
						</td>
					</ng-container>

					<ng-container matColumnDef="eRPCreatedId">
						<th mat-header-cell *matHeaderCellDef> ERP Id </th>
						<td mat-cell *matCellDef="let item"> {{item.erpCreatedId}} </td>
					</ng-container>

					<ng-container matColumnDef="delButton">
						<th mat-header-cell *matHeaderCellDef> </th>
						<td mat-cell *matCellDef="let item"> <button mat-flat-button color="warn" (click)="$event.stopPropagation();dismissItem(item)">Dismiss</button> </td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>

				</table>

			</div>
		</div>

	</mat-drawer-container>
</div>

