<div class="big-layout">
	<button mat-fab color="primary" 
			class="nav-button" 
			[routerLink]="['/dashboard']"
			matTooltip="Dashboard">
		<mat-icon>home</mat-icon>
	</button>
	<div class="vert-divider"></div>
	<button mat-fab color="accent" 
			class="nav-button" 
			[routerLink]="['/workflow']"
			matTooltip="Workflow">
		<mat-icon>air</mat-icon>
	</button>
	<button mat-fab color="primary" 
			class="nav-button" 
			[routerLink]="['/emailrequests']"
			matTooltip="Email Request Explorer">
		<mat-icon>mail</mat-icon>
	</button>
	<button mat-fab color="primary" 
			class="nav-button" 
			[routerLink]="['/parsedemails']"
			matTooltip="Parsed Email Explorer">
		<mat-icon>transcribe</mat-icon>
	</button>
	<button mat-fab color="primary" 
			class="nav-button" 
			[routerLink]="['/erpsubmissionattempts']"
			matTooltip="ERP Submission Attempt Explorer">
		<mat-icon>send</mat-icon>
	</button>
	<div class="vert-divider"></div>
	<button mat-fab color="primary" 
			class="nav-button" 
			[matMenuTriggerFor]="menu">
		<mat-icon>more_vert</mat-icon>
	</button>
</div>

<mat-menu #menu="matMenu">
	@if(localAuth.isAdmin) {
		<button mat-menu-item [routerLink]="['/aiengines']">
			<mat-icon>smart_toy</mat-icon>
			<span>AI Engines Configuration</span>
		</button>
		<button mat-menu-item [routerLink]="['/brokers']">
			<mat-icon>local_shipping</mat-icon>
			<span>Brokers</span>
		</button>
	}
	<button mat-menu-item [routerLink]="['/erpconnections']">
		<mat-icon>power</mat-icon>
		<span>ERP Connections</span>
	</button>
	@if(localAuth.isAdmin) {
		<button mat-menu-item [routerLink]="['/erpsystems']">
			<mat-icon>assignment</mat-icon>
			<span>ERP System Configuration</span>
		</button>
		<button mat-menu-item [routerLink]="['/logs']">
			<mat-icon>error</mat-icon>
			<span>Errors and logs</span>
		</button>
		<button mat-menu-item [routerLink]="['/users']">
			<mat-icon>group</mat-icon>
			<span>Users</span>
		</button>
	}
</mat-menu>
