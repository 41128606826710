import { Component } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';


@Component({
  selector: 'app-yesnoconfirm',
  standalone: true,
  imports: [MatBottomSheetModule, MatListModule],
  templateUrl: './yesnoconfirm.component.html',
  styleUrl: './yesnoconfirm.component.scss'
})
export class YesnoconfirmComponent {

	constructor(private bottomSheetRef: MatBottomSheetRef<YesnoconfirmComponent>)
	{

	}

	yes()
	{
		this.bottomSheetRef.dismiss(true);
	}

	no()
	{
		this.bottomSheetRef.dismiss(false);
	}

}
