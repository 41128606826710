<div class="parent-frame">
<mat-drawer-container class="drawer-container" autosize>
		<mat-drawer #drawer (closedStart)="drawerClose(drawer)" class="drawer-flyout" mode="over">

			@if(flyoutmode == 0) {

				<mat-form-field class="full-width-input">
					<mat-label>Broker</mat-label>
					<mat-select [(ngModel)]="selectedBroker" name="broker" disabled> 
						@for (broker of brokerDataSource; track broker) {
							<mat-option [value]="broker.id">{{broker.name}}</mat-option>
						}
					</mat-select>
				</mat-form-field>

				<mat-form-field class="full-width-input">
					<mat-label>ERP Connection</mat-label>
					<mat-select [(ngModel)]="selectedERPConnection" name="erpconnection" (selectionChange)="updateERPConnection()"> 
						@for (erpconnection of erpConnectionDataSource; track erpconnection) {
							<mat-option [value]="erpconnection.id">{{erpconnection.name}}</mat-option>
						}
					</mat-select>
				</mat-form-field>

				<mat-form-field class="half-width-input">
					<mat-select [(ngModel)]="selectedCustomer" (openedChange)="onCustomerDropdownOpen($event)">
						<!-- Search Input at the top of the dropdown -->
						<mat-option style="border: 2px dotted red">
							<input #searchInput matInput placeholder="Search customer name" [(ngModel)]="erpConnectionCustomerSearchTerm" (input)="filterCustomers()" (click)="$event.stopPropagation()" />
						</mat-option>

						<!-- Display filtered options -->
						<mat-option *ngFor="let customer of erpConnectionCustomerDataSourceFiltered" [value]="customer.id">
							{{ customer.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<button mat-flat-button color="primary" [disabled]="selectedCustomer == -1" (click)="associateDomain()">Associate sender domain with this customer</button>

				<mat-form-field class="full-width-input">
					<mat-label>Email Sender</mat-label>
					<input matInput type="text" [(ngModel)]="txtSender">
				</mat-form-field>

				<mat-form-field class="full-width-input">
					<mat-label>Email Recipient</mat-label>
					<input matInput type="text" [(ngModel)]="txtRecipient">
				</mat-form-field>

				<mat-form-field class="full-width-input">
					<mat-label>Email Subject</mat-label>
					<input matInput type="text" [(ngModel)]="txtSubject">
				</mat-form-field>

				<mat-form-field class="full-width-input">
					<mat-label>Email Body</mat-label>
					<textarea matInput matTextareaAutosize matAutosizeMinRows=10 style="height:30vh" [(ngModel)]="txtBody"></textarea>
				</mat-form-field>

				<div (dragenter)="onFileDragEnter($event)" (dragover)="onFileDragOver($event)" (dragleave)="onFileDragLeave($event)" (drop)="onFileDrop($event)" class="file-upload">
					<mat-form-field appearance="outline" class="half-width-input">
						<mat-label>Attach File</mat-label>

						<!-- Hidden file input element -->
						<input type="file" (change)="onFileSelected($event)" #fileInput hidden />

						<!-- Custom input to display the file name -->
						<input
							matInput
							[value]="chosenFileName"
							placeholder="No file chosen"
							readonly
							class="file-name-input"
						/>

						<!-- Button to trigger file input dialog -->
						<button
							mat-button
							matSuffix
							(click)="fileInput.click()"
							aria-label="Upload File"
							class="upload-button">
							Select File
						</button>
					</mat-form-field>

					<button mat-flat-button color="primary" (click)="removeFile()">Remove file</button>

					<span>Max file size: 1 MB</span>

				</div>

			}
			@else {
<!-- BEGIN DETAILED EMAIL FLYOUT -->
				<div class="splitview">
					<div class="leftsplit">
						<mat-form-field class="full-width-input">
							<mat-label>Email Sender</mat-label>
							<input matInput type="text" [(ngModel)]="selectedEmailRequest.emailSender">
						</mat-form-field>

						<mat-form-field class="full-width-input">
							<mat-label>Email Recipient</mat-label>
							<input matInput type="text" [(ngModel)]="selectedEmailRequest.emailRecipient">
						</mat-form-field>

						<mat-form-field class="full-width-input">
							<mat-label>Email Subject</mat-label>
							<input matInput type="text" [(ngModel)]="selectedEmailRequest.emailSubject">
						</mat-form-field>

						<mat-form-field class="full-width-input">
							<mat-label>Email Body</mat-label>
							<textarea matInput matTextareaAutosize matAutosizeMinRows=10 style="height:30vh" [(ngModel)]="selectedEmailRequest.emailBody"></textarea>
						</mat-form-field>

						@if(selectedEmailRequest.attachmentGuid)
						{
							<button mat-fab matTooltip="Open attachment" aria-label="Open attachment" (click)="openAttachment(drawer)" style="margin-left: 10px">
								<mat-icon>attach_file</mat-icon>
							</button>
						}

					</div>

					<div class="middlesplit">
						<mat-icon style="transform: scale(2)">double_arrow</mat-icon>
					</div>

					<div class="rightsplit">
						@if(selectedParsedEmailDetailed.orderObject) {

							<mat-form-field class="full-width-input">
								<mat-label>Order Type</mat-label>
								<mat-select [(ngModel)]="selectedParsedEmailDetailed.orderObject.orderType" name="isPickupLiftgate">
									<mat-option [value]="0">LTL Order</mat-option>
									<mat-option [value]="1">FTL Order</mat-option>
								</mat-select>
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Equipment Type</mat-label>
								<mat-select [(ngModel)]="selectedParsedEmailDetailed.orderObject.equipmentType" name="equipmentType">
									<mat-option [value]="11">Conestoga</mat-option>
									<mat-option [value]="20">Flatbed</mat-option>
									<mat-option [value]="32">Flatbed, Van, or Reefer</mat-option>
									<mat-option [value]="42">LTL</mat-option>
									<mat-option [value]="48">Power Only</mat-option>
									<mat-option [value]="50">Reefer</mat-option>
									<mat-option [value]="78">Van</mat-option>
									<mat-option [value]="45">Not Specified</mat-option>
								</mat-select>
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Pickup Date</mat-label>
									<input matInput [matDatepicker]="picker1" [(ngModel)]="selectedParsedEmailDetailed.orderObject.pickupDate" name="pickupDate">
									<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
								<mat-datepicker #picker1></mat-datepicker>
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Ready Time</mat-label>
								<input matInput type="time" [(ngModel)]="selectedParsedEmailDetailed.orderObject.readyTime" name="readyTime">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Close Time</mat-label>
								<input matInput type="time" [(ngModel)]="selectedParsedEmailDetailed.orderObject.closeTime" name="closeTime">
							</mat-form-field>

							<div class="orderitemborder">
								<mat-form-field class="full-width-input">
									<mat-label>ERP Contact</mat-label>
									<mat-select [(ngModel)]="selectedERPConnectionContact" name="erpconnectioncontact"> 
										@for (erpconnectioncontact of erpConnectionContactDataSource; track erpconnectioncontact) {
											<mat-option [value]="erpconnectioncontact.id">{{erpconnectioncontact.contactName}}</mat-option>
										}
									</mat-select>
								</mat-form-field>

								<button mat-flat-button class="full-width-input" color="primary" (click)="populateShipperContactInformation()">Populate Shipper Contact Information</button>
							</div>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper Business Name</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmailDetailed.orderObject.shipperBusinessName" name="shipperBusinessName">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper Address</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmailDetailed.orderObject.shipperAddress" name="shipperAddress">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper City</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmailDetailed.orderObject.shipperCity" name="shipperCity">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper State</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmailDetailed.orderObject.shipperState" name="shipperState">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper Zip</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmailDetailed.orderObject.shipperZip" name="shipperZip">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper Phone</mat-label>
								<input matInput type="tel" [(ngModel)]="selectedParsedEmailDetailed.orderObject.shipperPhone" name="shipperPhone">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper Name</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmailDetailed.orderObject.shipperName" name="shipperName">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper Email</mat-label>
								<input matInput type="email" [(ngModel)]="selectedParsedEmailDetailed.orderObject.shipperEmail" name="shipperEmail">
							</mat-form-field>

							<button mat-flat-button color="primary" (click)="reverseShipperConsignee()">Reverse Shipper and Consignee</button>

							<div class="orderitemborder">
								<mat-form-field class="full-width-input">
									<mat-label>ERP Contact</mat-label>
									<mat-select [(ngModel)]="selectedERPConnectionContact" name="erpconnectioncontact"> 
										@for (erpconnectioncontact of erpConnectionContactDataSource; track erpconnectioncontact) {
											<mat-option [value]="erpconnectioncontact.id">{{erpconnectioncontact.contactName}}</mat-option>
										}
									</mat-select>
								</mat-form-field>

								<button mat-flat-button class="full-width-input" color="primary" (click)="populateConsigneeContactInformation()">Populate Consignee Contact Information</button>
							</div>

							<mat-form-field class="full-width-input">
								<mat-label>Consignee Business Name</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmailDetailed.orderObject.consigneeBusinessName" name="consigneeBusinessName">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Consignee Address</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmailDetailed.orderObject.consigneeAddress" name="consigneeAddress">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Consignee City</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmailDetailed.orderObject.consigneeCity" name="consigneeCity">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Consignee State</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmailDetailed.orderObject.consigneeState" name="consigneeState">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Consignee Zip</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmailDetailed.orderObject.consigneeZip" name="consigneeZip">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Consignee Phone</mat-label>
								<input matInput type="tel" [(ngModel)]="selectedParsedEmailDetailed.orderObject.consigneePhone" name="consigneePhone">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Consignee Name</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmailDetailed.orderObject.consigneeName" name="consigneeName">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Consignee Email</mat-label>
								<input matInput type="email" [(ngModel)]="selectedParsedEmailDetailed.orderObject.consigneeEmail" name="consigneeEmail">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Pallet Count</mat-label>
								<input matInput type="number" [(ngModel)]="selectedParsedEmailDetailed.orderObject.palletCount" type="number" name="palletCount">
							</mat-form-field>


<!-- START SECTION ON ITEM DETAILS -->
							<div class="orderitemborder">
								<mat-form-field class="half-width-input">
									<mat-label>Cargo Items</mat-label>
									<mat-select [(ngModel)]="selectedOrderItem" name="cargoItem"> 
										@for (cargoItem of selectedParsedEmailDetailed.orderObject.orderItems; track cargoItem) {
											<mat-option [value]="cargoItem">{{cargoItem.productDescription}}</mat-option>
										}
									</mat-select>
								</mat-form-field>

								<button style="margin-left:10px" matTooltip="Add New Cargo Item" mat-fab (click)="addOrderItem()">
									<mat-icon>add</mat-icon>
								</button>
								<button style="margin-left:10px" matTooltip="Delete Cargo Item" mat-fab (click)="deleteOrderItem()">
									<mat-icon>delete</mat-icon>
								</button>



								@if(selectedOrderItem != null) {
									<mat-form-field class="full-width-input">
										<mat-label>Piece Count</mat-label>
										<input matInput type="number" [(ngModel)]="selectedOrderItem.pieceCount" type="number" name="pieceCount">
									</mat-form-field>

									<mat-form-field class="full-width-input">
										<mat-label>Package Type</mat-label>
										<mat-select [(ngModel)]="selectedOrderItem.packageType" name="packageType">
											<mat-option [value]="1">None</mat-option>
											<mat-option [value]="2">Pallets</mat-option>
											<mat-option [value]="3">Boxes</mat-option>
											<mat-option [value]="4">Bundles</mat-option>
											<mat-option [value]="5">Cartons</mat-option>
											<mat-option [value]="6">Cases</mat-option>
											<mat-option [value]="7">Crates</mat-option>
											<mat-option [value]="8">Drums</mat-option>
											<mat-option [value]="9">Pails</mat-option>
											<mat-option [value]="10">Rolls</mat-option>
											<mat-option [value]="11">Stacks</mat-option>
											<mat-option [value]="12">Totes</mat-option>
											<mat-option [value]="13">Wheels</mat-option>
											<mat-option [value]="16">Pieces</mat-option>
											<mat-option [value]="17">Bags</mat-option>
											<mat-option [value]="18">Cylinders</mat-option>
											<mat-option [value]="19">Buckets</mat-option>
											<mat-option [value]="20">Skids</mat-option>
											<mat-option [value]="21">Packages</mat-option>
											<mat-option [value]="22">Units</mat-option>
											<mat-option [value]="23">Bins</mat-option>
											<mat-option [value]="24">Coils</mat-option>
											<mat-option [value]="25">Gallons</mat-option>
											<mat-option [value]="26">Containers</mat-option>
											<mat-option [value]="27">Loose Bags</mat-option>
											<mat-option [value]="28">Poles</mat-option>
											<mat-option [value]="29">Reels</mat-option>
											<mat-option [value]="30">Sheets</mat-option>
											<mat-option [value]="32">Gaylords</mat-option>
											<mat-option [value]="33">Loose</mat-option>
											<mat-option [value]="34">Mixed Pallets</mat-option>
											<mat-option [value]="35">Supersacks</mat-option>
											<mat-option [value]="36">Railcar</mat-option>
											<mat-option [value]="37">Tiles</mat-option>
											<mat-option [value]="38">Cans</mat-option>
											<mat-option [value]="39">Jerricans</mat-option>
											<mat-option [value]="40">Carts</mat-option>
											<mat-option [value]="41">Tanks</mat-option>
											<mat-option [value]="42">Tubes</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field class="full-width-input">
										<mat-label>Product Description</mat-label>
										<input matInput type="text" [(ngModel)]="selectedOrderItem.productDescription" name="productDescription">
									</mat-form-field>

									<mat-form-field class="half-width-input">
										<mat-label>Weight</mat-label>
										<input matInput type="number" [(ngModel)]="selectedOrderItem.weight" type="number" name="weight">
									</mat-form-field>

									<mat-form-field class="quarter-width-input" style="margin-left: 5px">
										<mat-label>Weight Units</mat-label>
										<mat-select [(ngModel)]="selectedOrderItem.weightUnits">
											<mat-option value="">Unknown</mat-option>
											<mat-option value="lb">lb</mat-option>
											<mat-option value="mg">mg</mat-option>
											<mat-option value="g">g</mat-option>
											<mat-option value="kg">kg</mat-option>
											<mat-option value="mt">mt</mat-option>
											<mat-option value="oz">oz</mat-option>
											<mat-option value="t">t</mat-option>
										</mat-select>
									</mat-form-field>	

									@if(selectedParsedEmailDetailed.orderObject.orderType == 0) {

										<mat-form-field class="half-width-input">
											<mat-label>Length</mat-label>
											<input matInput [(ngModel)]="selectedOrderItem.length" type="number" name="length">
										</mat-form-field>

										<mat-form-field class="quarter-width-input" style="margin-left: 5px">
											<mat-label>Length Units</mat-label>
											<mat-select [(ngModel)]="selectedOrderItem.dimensionUnits">
												<mat-option value="">Unknown</mat-option>
												<mat-option value="mm">mm</mat-option>
												<mat-option value="cm">cm</mat-option>
												<mat-option value="m">m</mat-option>
												<mat-option value="in">in</mat-option>
												<mat-option value="ft">ft</mat-option>
												<mat-option value="yd">yd</mat-option>
											</mat-select>
										</mat-form-field>	

										<mat-form-field class="half-width-input">
											<mat-label>Width</mat-label>
											<input matInput [(ngModel)]="selectedOrderItem.width" type="number" name="width">
										</mat-form-field>

										<mat-form-field class="quarter-width-input" style="margin-left: 5px">
											<mat-label>Width Units</mat-label>
											<mat-select [(ngModel)]="selectedOrderItem.dimensionUnits">
												<mat-option value="">Unknown</mat-option>
												<mat-option value="mm">mm</mat-option>
												<mat-option value="cm">cm</mat-option>
												<mat-option value="m">m</mat-option>
												<mat-option value="in">in</mat-option>
												<mat-option value="ft">ft</mat-option>
												<mat-option value="yd">yd</mat-option>
											</mat-select>
										</mat-form-field>	

										<mat-form-field class="half-width-input">
											<mat-label>Height</mat-label>
											<input matInput [(ngModel)]="selectedOrderItem.height" type="number" name="height">
										</mat-form-field>

										<mat-form-field class="quarter-width-input" style="margin-left: 5px">
											<mat-label>Height Units</mat-label>
											<mat-select [(ngModel)]="selectedOrderItem.dimensionUnits">
												<mat-option value="">Unknown</mat-option>
												<mat-option value="mm">mm</mat-option>
												<mat-option value="cm">cm</mat-option>
												<mat-option value="m">m</mat-option>
												<mat-option value="in">in</mat-option>
												<mat-option value="ft">ft</mat-option>
												<mat-option value="yd">yd</mat-option>
											</mat-select>
										</mat-form-field>	

										<mat-form-field class="full-width-input">
											<mat-label>Cargo Class</mat-label>
											<input matInput [(ngModel)]="selectedOrderItem.cargoClass" name="cargoClass">
										</mat-form-field>

										<mat-form-field class="full-width-input">
											<mat-label>NMFC Number</mat-label>
											<input matInput [(ngModel)]="selectedOrderItem.nmfcNumber" name="nmfcNumber">
										</mat-form-field>

										<mat-form-field class="half-width-input">
											<mat-label>PCF</mat-label>
											<input matInput [(ngModel)]="selectedOrderItem.pcf" type="number" name="pcf">
										</mat-form-field>

										<button style="margin-left:10px" matTooltip="PCF Calculator" mat-fab (click)="togglePCFCalculator($event)">
											<mat-icon>square_foot</mat-icon>
										</button>
									}

								}
							</div>

<!-- END SECTION ON ITEM DETAILS -->

							<mat-form-field class="full-width-input">
								<mat-label>Special Notes</mat-label>
								<textarea matInput [(ngModel)]="selectedParsedEmailDetailed.orderObject.specialNotes" name="specialNotes"></textarea>
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipper Number</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmailDetailed.orderObject.shipperNumber" name="shipperNumber">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>PO Number</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmailDetailed.orderObject.poNumber" name="poNumber">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>GL Code</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmailDetailed.orderObject.glCode" name="glCode">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipment Value</mat-label>
								<input matInput type="number" [(ngModel)]="selectedParsedEmailDetailed.orderObject.shipmentValue" name="shipmentValue">
							</mat-form-field>

							<mat-form-field class="full-width-input">
								<mat-label>Shipment Value Currency</mat-label>
								<input matInput type="text" [(ngModel)]="selectedParsedEmailDetailed.orderObject.shipmentValueCCY" name="shipmentValueCCY">
							</mat-form-field>

							<mat-checkbox [(ngModel)]="selectedParsedEmailDetailed.orderObject.isPickupLiftgate">Is Pickup Liftgate</mat-checkbox>

							<mat-checkbox [(ngModel)]="selectedParsedEmailDetailed.orderObject.isDeliveryLiftgate">Is Delivery Liftgate</mat-checkbox>

							<mat-checkbox [(ngModel)]="selectedParsedEmailDetailed.orderObject.isPickupResidential">Is Pickup Residential</mat-checkbox>

							<mat-checkbox [(ngModel)]="selectedParsedEmailDetailed.orderObject.isDeliveryResidential">Is Delivery Residential</mat-checkbox>

							<mat-checkbox [(ngModel)]="selectedParsedEmailDetailed.orderObject.isPickupLimitedAccess">Is Pickup Limited Access</mat-checkbox>

							<mat-checkbox [(ngModel)]="selectedParsedEmailDetailed.orderObject.isDeliveryLimitedAccess">Is Delivery Limited Access</mat-checkbox>

							<mat-checkbox [(ngModel)]="selectedParsedEmailDetailed.orderObject.isDeliveryAppointment">Is Delivery Appointment</mat-checkbox>

							<!-- this is an FTL order -->
							@if(selectedParsedEmailDetailed.orderObject.orderType == 1) {

								<mat-form-field class="full-width-input">
									<mat-label>Receiving Ready Time</mat-label>
									<input matInput type="time" [(ngModel)]="selectedParsedEmailDetailed.orderObject.receivingReadyTime" name="receivingReadyTime">
								</mat-form-field>

								<mat-form-field class="full-width-input">
									<mat-label>Receiving Close Time</mat-label>
									<input matInput type="time" [(ngModel)]="selectedParsedEmailDetailed.orderObject.receivingCloseTime" name="receivingCloseTime">
								</mat-form-field>

								<mat-form-field class="full-width-input">
									<mat-label>Required Linear Feet</mat-label>
									<input matInput type="number" [(ngModel)]="selectedParsedEmailDetailed.orderObject.requiredLinearFeet" name="requiredLinearFeet">
								</mat-form-field>

							}

							<mat-form-field class="full-width-input">
								<mat-label>AI Questions</mat-label>
								<textarea matInput [(ngModel)]="selectedParsedEmailDetailed.orderObject.gptQuestions" name="gptQuestions"></textarea>
							</mat-form-field>
						}
					</div>
				</div>
			}

			@if(flyoutmode == 0 && !isSavingNewEmail) {
				<div class="full-width-input">
					<button mat-flat-button color="primary" (click)="saveNewEmail(drawer)">Save New Email</button>
					<button mat-flat-button color="warn" (click)="closeClicked(drawer)">Close</button>
				</div>
			}
			@else if(flyoutmode == 0 && isSavingNewEmail) {
				<div class="full-width-input" [ngClass]="{ 'loading-animation': isSavingNewEmail}">
					<button mat-flat-button color="primary" (click)="saveNewEmail(drawer)" disabled>Save New Email</button>
					<button mat-flat-button color="warn" (click)="closeClicked(drawer)" disabled>Close</button>
				</div>
			}

			@if(flyoutmode == 1 && selectedParsedEmailDetailed.orderObject) {
				<div class="full-width-input">
					<button mat-flat-button color="primary" (click)="createDerivedParsedEmail(drawer)">Save Updates</button>
					<button mat-flat-button color="accent" (click)="replyEmail()">Create Reply Email w/ Questions</button>
					<button mat-flat-button color="warn" (click)="closeClicked(drawer)">Close</button>
				</div>
			}

		</mat-drawer>

		<div class="body-frame">
			<div class="nav-bar">
				<app-naviconbar></app-naviconbar>
			</div>

			<div class="nav-bar">
				<mat-radio-group style="padding: 5px" [ngClass]="{ 'loading-animation': isBrokerLoading }" aria-label="Broker or User Search" [(ngModel)]="searchSelection">
					<mat-radio-button [value]="1">
						<!-- broker combo box dropdown -->
						<mat-form-field>
							<mat-label>Broker</mat-label>
							<mat-select [(ngModel)]="selectedBroker" name="broker">
								@for (broker of brokerDataSource; track broker) {
									<mat-option [value]="broker.id">{{broker.name}}</mat-option>
								}
							</mat-select>
						</mat-form-field>
					</mat-radio-button>
					<mat-radio-button [value]="2">
						<!-- user combo box dropdown -->
						<mat-form-field>
							<mat-label>User</mat-label>
							<mat-select [(ngModel)]="selectedUser" name="user">
								@for (user of userDataSource; track user) {
									<mat-option [value]="user.user_id">{{user.name}}</mat-option>
								}
							</mat-select>
						</mat-form-field>
					</mat-radio-button>
				</mat-radio-group>

				<button mat-flat-button color="primary" (click)="refreshItems()">Refresh Email Requests</button>

				<mat-chip-option [selected]="showDismissed" (selectionChange)="onIncludeDismissedChange($event)" style="height: 50%">Include dismissed items</mat-chip-option>

			</div>

			<button class="floating-new-button" mat-fab (click)="newEmail(drawer)">
				<mat-icon>add</mat-icon>
			</button>

			<div class="three-column-frame">
				<div class="leftframe column">
					<div class="watermark-icon">
						<mat-icon>mail</mat-icon>
					</div>
					<div [ngClass]="{ 'loading-animation': isEmailLoading }" class="columncontent email-list" (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)" (dragover)="onDragOver($event)" (drop)="onDrop($event, drawer)">
						<mat-list role="list">
							@for (emailReq of emailRequestDataSource; track emailReq; let i = $index) {
								<mat-list-item role="listitem" (click)="emailRequestClick(emailReq)" [ngClass]="{ 'selected-item' : selectedEmailRequest.id == emailReq.id }">
									<div class="mat-item-layout" [ngClass]="{'new': i === 0}">
										<div class="mat-item-top">{{emailReq.emailSender}} | {{emailReq.createDate | date:'full'}}</div>
										@if(emailReq.isDismissed)
										{
											<div class="mat-item-bottom" style="text-decoration: line-through">{{emailReq.emailSubject}}</div>
										}
										@else
										{
											<div class="mat-item-bottom">{{emailReq.emailSubject}}</div>
										}
										<div class="mat-item-button">
											@if(emailReq.mostRecentSuccessfulERPSubmissionId != null) {
												<mat-icon matTooltip="Successfully sent to ERP" style="color: green">verified</mat-icon>
											}

											@if(emailReq.attachmentGuid != null && emailReq.attachmentGuid != "") {
												<mat-icon matTooltip="Email has file attachment" style="color: black">attach_file</mat-icon>
											}

											<button mat-mini-fab matTooltip="Dismiss" [hidden]="selectedEmailRequest.id != emailReq.id" (click)="$event.stopPropagation();dismissEmail(emailReq)"><mat-icon>delete</mat-icon></button>
											<button mat-mini-fab matTooltip="Parse using AI" [hidden]="selectedEmailRequest.id != emailReq.id" (click)="$event.stopPropagation();createParsedEmail(emailReq)"><mat-icon>transcribe</mat-icon></button>
										</div>
									</div>
								</mat-list-item>
							}
						</mat-list>

					</div>
				</div>
				<div class="drag-handle"></div>
				<div class="middleframe column">
					<div class="watermark-icon">
						<mat-icon>transcribe</mat-icon>
					</div>
					<div [ngClass]="{ 'loading-animation': isParsedLoading }" class="columncontent">
						@if(parsedEmailDataSource.length == 0) {
							No Data
						}
						@else {
							<mat-list role="list">
								@for (parsedEmail of parsedEmailDataSource; track parsedEmail; let i = $index) {
									<mat-list-item role="listitem" (click)="parsedEmailClick(parsedEmail)" [ngClass]="{ 'selected-item' : selectedParsedEmail.id == parsedEmail.id }">
										<div class="mat-item-layout" [ngClass]="{'new': i === 0}">
											<div class="mat-item-top">{{parsedEmail.id}}: {{parsedEmail.createDate | date:'full'}}</div>
											<div class="mat-item-bottom">
												<div class="progress-container">
													<div class="progress-bar" [style.width.%]="parsedEmail.percentComplete"></div>
													<span class="progress-text">{{ parsedEmail.percentComplete }}%</span>
												</div>


											</div>
											<div class="mat-item-button">
												@if(parsedEmail.status == 0) {
													<mat-icon matTooltip="Not submitted to AI">pending</mat-icon>
												}
												@if(parsedEmail.status == 1) {
													<mat-icon matTooltip="Submitted to AI, awaiting response">hourglass_top</mat-icon>
												}
												@if(parsedEmail.status == 2) {
													<mat-icon matTooltip="Successfully parsed by AI" style="color: green">check_circle</mat-icon>
												}
												@if(parsedEmail.status == 3) {
													<mat-icon matTooltip="AI parsing failed" style="color: red">error</mat-icon>
												}

												<button mat-mini-fab matTooltip="Details" [hidden]="selectedParsedEmail.id != parsedEmail.id" (click)="$event.stopPropagation();showParsedEmailDetail(drawer, parsedEmail)"><mat-icon>info</mat-icon></button>
												@if(selectedParsedEmail.status == 2) {
													<button mat-mini-fab matTooltip="Submit to ERP" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" [hidden]="selectedParsedEmail.id != parsedEmail.id"><mat-icon>send</mat-icon></button>
												}
											</div>
										</div>
									</mat-list-item>
								}
							</mat-list>
						}
					</div>
				</div>
				<div class="drag-handle"></div>
				<div class="rightframe column">
					<div class="watermark-icon">
						<mat-icon>send</mat-icon>
					</div>
					<div [ngClass]="{ 'loading-animation': isERPLoading }" class="columncontent">
						@if(erpSubmissionAttemptDataSource.length == 0) {
							No Data
						}
						@else {
							<mat-list role="list">
								@for (erpSubmission of erpSubmissionAttemptDataSource; track erpSubmission; let i = $index) {
									<mat-list-item role="listitem">
										<div class="mat-item-layout" [ngClass]="{'new': i === 0}">
											<div class="mat-item-top">{{erpSubmission.id}}: {{erpSubmission.lastUpdateDate | date:'full'}}</div>
											<div class="mat-item-bottom">
												@if(erpSubmission.status == 0) {
													Waiting...	
												}
												@if(erpSubmission.status == 1) {
													Working...
												}
												@if(erpSubmission.status == 2) {
													@if(erpSubmission.erpHyperlink != null) {
														<a href="{{erpSubmission.erpHyperlink}}" target="_blank">ERP Id: {{erpSubmission.erpCreatedId}}</a>
													}
													@else {
														ERP Id: {{erpSubmission.erpCreatedId}}
													}
												}
												@if(erpSubmission.status == 3) {
													<span matTooltip="{{erpSubmission.httpResponseText}}">{{erpSubmission.httpResponseText}}</span>
												}
											</div>
											<div class="mat-item-button">
												@if(erpSubmission.status == 0) {
													<mat-icon matTooltip="Not submitted to ERP">pending</mat-icon>
												}
												@if(erpSubmission.status == 1) {
													<mat-icon matTooltip="Submitted to ERP, awaiting response">hourglass_top</mat-icon>
												}
												@if(erpSubmission.status == 2) {
													<mat-icon matTooltip="Successfully sent to ERP" style="color: green">check_circle</mat-icon>
												}
												@if(erpSubmission.status == 3) {
													<mat-icon matTooltip="ERP submission failed" style="color: red">error</mat-icon>
												}
											</div>
										</div>
									</mat-list-item>
								}
							</mat-list>
						}
					</div>
				</div>
			</div>
		</div>

	</mat-drawer-container>
</div>

<!-- createSubmission(conn.id) -->

<mat-menu #menu="matMenu">
	@for (conn of erpConnectionDataSource; track conn) {
		<button mat-menu-item [matMenuTriggerFor]="submitStatusMenu" [matMenuTriggerData]="{connid: conn.id}">{{conn.name}}</button>
	}
</mat-menu>

<mat-menu #submitStatusMenu="matMenu">
	<ng-template matMenuContent let-connid="connid"> 
		<button mat-menu-item (click)="createSubmission(connid, 0)">Submit as Quoted</button>
		<button mat-menu-item (click)="createSubmission(connid, 1)">Submit as Booked</button>
	</ng-template>
</mat-menu>

@if(showPCF) {
	<div class="popup-container" [ngStyle]="{'top.px': mouseY, 'left.px': mouseX}">
		<h2>Enter Dimensions and Weight</h2>
		<div class="popup-content">
			<div class="form-group">
				<label>Length:</label>
				<input type="number" placeholder="Length" [(ngModel)]="PCFLength" />
				<select [(ngModel)]="selectedPCFLengthUnit">
					<option>mm</option>
					<option>cm</option>
					<option>m</option>
					<option>in</option>
					<option>ft</option>
					<option>yd</option>
				</select>
			</div>

			<div class="form-group">
				<label>Height:</label>
				<input type="number" placeholder="Height" [(ngModel)]="PCFHeight" />
				<select [(ngModel)]="selectedPCFHeightUnit">
					<option>mm</option>
					<option>cm</option>
					<option>m</option>
					<option>in</option>
					<option>ft</option>
					<option>yd</option>
				</select>
			</div>

			<div class="form-group">
				<label>Width:</label>
				<input type="number" placeholder="Width" [(ngModel)]="PCFWidth" />
				<select [(ngModel)]="selectedPCFWidthUnit">
					<option>mm</option>
					<option>cm</option>
					<option>m</option>
					<option>in</option>
					<option>ft</option>
					<option>yd</option>
				</select>
			</div>

			<div class="form-group">
				<label>Weight:</label>
				<input type="number" placeholder="Weight" [(ngModel)]="PCFWeight" />
				<select [(ngModel)]="selectedPCFWeightUnit">
					<option>oz</option>
					<option>lb</option>
					<option>t</option>	
					<option>g</option>
					<option>mg</option>
					<option>kg</option>
					<option>mt</option>
				</select>
			</div>

			<div class="form-group">
				<label>Piece Count:</label>
				<input type="number" placeholder="Piece Count" [(ngModel)]="PCFPieceCount" />
			</div>

			<div class="output">
				<p>Calculated Value: <span id="calculation-output">{{ calculatedPCF }}</span></p>
			</div>

			<div class="button-group">
				<button mat-flat-button (click)="calculatePCF()">Calculate</button>
				<button mat-flat-button (click)="applyPCFToOrder()">Apply</button>
				<button mat-flat-button (click)="togglePCFCalculator($event)">Close</button>
			</div>
		</div>
	</div>
}

