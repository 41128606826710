<div class="parent-frame">

<!--
	<div class="login-fixed">
		<button mat-fab color="accent"
				class="nav-button"
				matTooltip="Refresh Login"
				(click)="login()">
			<mat-icon style="transform: scale(2)">lock_reset</mat-icon>
		</button>
	</div>
-->

	<div class="logout-fixed">
		<button mat-fab color="accent"
				class="nav-button"
				matTooltip="Logout"
				(click)="logout()">
			<mat-icon style="transform: scale(2)">logout</mat-icon>
		</button>
	</div>

	<div class="key-fixed">
		<button mat-fab color="accent"
				class="nav-button"
				matTooltip="Copy Bearer Token to Clipboard"
				(click)="copyBearerToken()">
			<mat-icon style="transform: scale(2)">key</mat-icon>
		</button>
	</div>

	<div class="face-fixed">
		<button mat-fab color="accent"
				class="nav-button"
				matTooltip="Copy Id Token to Clipboard"
				(click)="copyIdToken()">
			<mat-icon style="transform: scale(2)">face</mat-icon>
		</button>
	</div>

	<div class="reset-fixed">
		<button mat-fab color="accent"
				class="nav-button"
				matTooltip="Reset Password"
				(click)="resetPassword()">
			<mat-icon style="transform: scale(2)">lock_reset</mat-icon>
		</button>
	</div>


	<div class="big-child-frame">
		<button mat-fab color="accent" 
				class="nav-button" 
				[routerLink]="['/workflow']"
				matTooltip="Workflow">
			<mat-icon style="transform: scale(10)">air</mat-icon>
        </button>
	</div>
	<div class="child-frame">
		<button mat-fab 	
				color="primary" 
				class="nav-button" 
				[routerLink]="['/emailrequests']"
				matTooltip="Email Request Explorer">
			<mat-icon style="transform: scale(10)">mail</mat-icon>
        </button>
	</div>
	<div class="child-frame">
		<button mat-fab 	
				color="primary" 
				class="nav-button" 
				[routerLink]="['/parsedemails']"
				matTooltip="Parsed Email Explorer">
			<mat-icon style="transform: scale(10)">transcribe</mat-icon>
        </button>
	</div>
	<div class="child-frame">
		<button mat-fab 	
				color="primary" 
				class="nav-button" 
				[routerLink]="['/erpsubmissionattempts']"
				matTooltip="ERP Submission Attempt Explorer">
			<mat-icon style="transform: scale(10)">send</mat-icon>
        </button>
	</div>
	@if(localAuth.isAdmin) {
		<div class="child-frame">
			<button mat-fab color="primary" 
					class="nav-button" 
					[routerLink]="['/aiengines']"
					matTooltip="AI Engines Configuration">
				<mat-icon style="transform: scale(10)">smart_toy</mat-icon>
			</button>
		</div>
		<div class="child-frame">
			<button mat-fab 	
					color="primary" 
					class="nav-button" 
					[routerLink]="['/brokers']"
					matTooltip="Broker Configuration">
				<mat-icon style="transform: scale(10)">local_shipping</mat-icon>
			</button>
		</div>
	}
	<div class="child-frame">
		<button mat-fab 	
				color="primary" 
				class="nav-button" 
				[routerLink]="['/erpconnections']"
				matTooltip="ERP Connection Configuration">
			<mat-icon style="transform: scale(10)">power</mat-icon>
        </button>
	</div>
	@if(localAuth.isAdmin) {
		<div class="child-frame">
			<button mat-fab 	
					color="primary" 
					class="nav-button" 
					[routerLink]="['/erpsystems']"
					matTooltip="ERP System Configuration">
				<mat-icon style="transform: scale(10)">assignment</mat-icon>
			</button>
		</div>
		<div class="child-frame">
			<button mat-fab 	
					color="primary" 
					class="nav-button" 
					[routerLink]="['/dragtest']"
					matTooltip="Errors and Logs">
				<mat-icon style="transform: scale(10)">error</mat-icon>
			</button>
		</div>
		<div class="child-frame">
			<button mat-fab 	
					color="primary" 
					class="nav-button" 
					[routerLink]="['/users']"
					matTooltip="Users">
				<mat-icon style="transform: scale(10)">group</mat-icon>
			</button>
		</div>
	}


</div>
