import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExternalEmail } from '../models/externalemail.model';
import { UrlService } from './url.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalemailService {

	private svcUrl = '/ExternalEmails';

	constructor(private http: HttpClient, private url: UrlService, private auth: AuthenticationService) { }

	getExternalEmail(id: number, useImpliedSender?: boolean | null): Observable<ExternalEmail> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		let params = new HttpParams()
			.set('UseImpliedSender', (useImpliedSender == null ? false : useImpliedSender));
		return this.http.get<ExternalEmail>(this.url.getBaseURL() + this.svcUrl + '/' + id, { headers, params });
	}

	getExternalEmailsForBrokerId(id: number, isDismissed?: boolean | null, itemStart?: number | null, itemCount?: number | null) {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		let params = new HttpParams()
			.set('IsDismissed', (isDismissed == null ? false : isDismissed))
			.set('ItemStart', (itemStart == null ? 0  : itemStart))
			.set('ItemCount', (itemCount == null ? 100 : itemCount));
		return this.http.get<ExternalEmail[]>(this.url.getBaseURL() + this.svcUrl + '/Broker/' + id, { headers, params });
	}

	getExternalEmailsForUserId(id: string, isDismissed?: boolean | null, itemStart?: number | null, itemCount?: number | null) {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		let params = new HttpParams()
			.set('IsDismissed', (isDismissed == null ? false : isDismissed))
			.set('ItemStart', (itemStart == null ? 0  : itemStart))
			.set('ItemCount', (itemCount == null ? 100 : itemCount));
		return this.http.get<ExternalEmail[]>(this.url.getBaseURL() + this.svcUrl + '/User/' + id, { headers, params });
	}

	getFullExternalEmailsForBrokerId(id: number, isDismissed?: boolean | null, itemStart?: number | null, itemCount?: number | null) {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		let params = new HttpParams()
			.set('IsDismissed', (isDismissed == null ? false : isDismissed))
			.set('ItemStart', (itemStart == null ? 0  : itemStart))
			.set('ItemCount', (itemCount == null ? 100 : itemCount));
		return this.http.get<ExternalEmail[]>(this.url.getBaseURL() + this.svcUrl + '/BrokerFull/' + id, { headers, params });
	}

	getFullExternalEmailsForUserId(id: string, isDismissed?: boolean | null, itemStart?: number | null, itemCount?: number | null) {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		let params = new HttpParams()
			.set('IsDismissed', (isDismissed == null ? false : isDismissed))
			.set('ItemStart', (itemStart == null ? 0  : itemStart))
			.set('ItemCount', (itemCount == null ? 100 : itemCount));
		return this.http.get<ExternalEmail[]>(this.url.getBaseURL() + this.svcUrl + '/UserFull/' + id, { headers, params });
	}

	dismissExternalEmail(id: number): Observable<ExternalEmail> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<ExternalEmail>(this.url.getBaseURL() + this.svcUrl + '/Dismiss/' + id, {}, { headers });
	}
}
