import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { UrlService } from './url.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

	private svcUrl = '/Users';

	constructor(private http: HttpClient, private url: UrlService, private auth: AuthenticationService) { }

	getAllUsers(): Observable<User[]> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<User[]>(this.url.getBaseURL() + this.svcUrl + "/AllUsers", { headers });
	}

	getUsers(): Observable<User[]> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<User[]>(this.url.getBaseURL() + this.svcUrl, { headers });
	}

	getUser(id: number): Observable<User> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<User>(this.url.getBaseURL() + this.svcUrl + '/' + id, { headers });
	}

	createUser(item: User): Observable<User> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<User>(this.url.getBaseURL() + this.svcUrl, item, { headers });
	}

}
