<h4 style="margin:10px">Are you sure?</h4>
<mat-nav-list>
	<a mat-list-item (click)="yes()">
		<span mat-line>Yes</span>
	</a>

	<a mat-list-item (click)="no()">
		<span mat-line>No</span>
	</a>
</mat-nav-list>
