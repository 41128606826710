import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Broker } from '../models/broker.model';
import { UrlService } from './url.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class BrokerService {

	private svcUrl = '/Brokers';

	constructor(private http: HttpClient, private url: UrlService, private auth: AuthenticationService) { 

	}

	getBrokers(): Observable<Broker[]> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<Broker[]>(this.url.getBaseURL() + this.svcUrl, { headers });
	}

	getBroker(id: number): Observable<Broker> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<Broker>(this.url.getBaseURL() + this.svcUrl + '/' + id, { headers });
	}

	createBroker(item: Broker): Observable<Broker> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<Broker>(this.url.getBaseURL() + this.svcUrl, item, { headers });
	}


}
