import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideAuth0 } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';

import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
	provideAuth0({
//		domain: 'dev-27mhkeg5h2cr6kn1.us.auth0.com',
//		clientId: 'aK6kwBIXA3G9fe0DjnQHbmkvUb68dPMv',
//		domain: 'dev-vu1ncrwjkvdx0ox2.us.auth0.com',
//		clientId: 'ymbG1G3Ry8UsW2k56s4qsLcqx3VctO4L',
		domain: environment.domain,
		clientId: environment.clientId,
		authorizationParams: {
			redirect_uri: window.location.origin,
//			audience: 'https://api-uat.turbotl.com',
			audience: environment.audience
		}
	}),
	provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes), provideAnimationsAsync(), provideHttpClient(), provideAnimationsAsync()]
};
