<div class="parent-frame">
	<mat-drawer-container class="drawer-container" autosize>
		<mat-drawer #drawer class="drawer-flyout" mode="side">
			<mat-form-field class="full-width-input">
				<mat-label>AI Engine Name</mat-label>
				<input matInput type="text" [(ngModel)]="txtAIEngineName">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>Engine Unique Id</mat-label>
				<input matInput type="number" [(ngModel)]="txtEngineUniqueId">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>Deployment Name</mat-label>
				<input matInput type="text" [(ngModel)]="txtDeploymentName">
			</mat-form-field>

			<button mat-flat-button color="primary" (click)="saveNew()">Save New AI Engine</button>

			<button mat-flat-button color="warn" (click)="cancelClicked(drawer)">Cancel</button>

		</mat-drawer>

		<div class="body-frame">
			<div class="nav-bar">
				<app-naviconbar></app-naviconbar>

			</div>

			<div class="nav-bar">
			
				<button mat-flat-button color="primary" (click)="drawer.open()">Add AI Engine</button>
				<button mat-flat-button color="primary" (click)="refreshItems()">Refresh AI Engine List</button>
			</div>

			<div>
				<table [ngClass]="{ 'loading-animation': isLoading }" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef> Id </th>
						<td mat-cell *matCellDef="let item"> {{item.id}} </td>
					</ng-container>

					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef> Name </th>
						<td mat-cell *matCellDef="let item"> {{item.name}} </td>
					</ng-container>

					<ng-container matColumnDef="engineUniqueId">
						<th mat-header-cell *matHeaderCellDef> Engine Unique Id </th>
						<td mat-cell *matCellDef="let item"> {{item.engineUniqueId}} </td>
					</ng-container>

					<ng-container matColumnDef="deploymentName">
						<th mat-header-cell *matHeaderCellDef> Deployment Name </th>
						<td mat-cell *matCellDef="let item"> {{item.deploymentName}} </td>
					</ng-container>

					<ng-container matColumnDef="delButton">
						<th mat-header-cell *matHeaderCellDef> </th>
						<td mat-cell *matCellDef="let item"> <button mat-flat-button color="warn" (click)="deleteItem(item)">Delete</button> </td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;" (click)="rowClicked(row)"></tr>

				</table>
			</div>
		</div>

	</mat-drawer-container>
</div>

