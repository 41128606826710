<div class="parent-frame">
	<mat-drawer-container class="drawer-container" autosize>
		<mat-drawer #drawer class="drawer-flyout" mode="side">
			<mat-form-field class="full-width-input">
				<mat-label>Broker Name</mat-label>
				<input matInput type="text" [(ngModel)]="txtBrokerName">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>DBA</mat-label>
				<input matInput type="text" [(ngModel)]="txtDBA">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>Address</mat-label>
				<input matInput type="text" [(ngModel)]="txtAddress">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>City</mat-label>
				<input matInput type="text" [(ngModel)]="txtCity">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>State</mat-label>
				<input matInput type="text" [(ngModel)]="txtState">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>Zip Code</mat-label>
				<input matInput type="text" [(ngModel)]="txtZip">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>Phone</mat-label>
				<input matInput type="text" [(ngModel)]="txtPhone">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>Contact Person</mat-label>
				<input matInput type="text" [(ngModel)]="txtContactPerson">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>Contact Email</mat-label>
				<input matInput type="text" [(ngModel)]="txtContactEmail">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>Contact Phone</mat-label>
				<input matInput type="text" [(ngModel)]="txtContactPhone">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>Default Recipient Email</mat-label>
				<input matInput type="text" [(ngModel)]="txtDefaultRecipientEmail">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>Email Domain</mat-label>
				<input matInput type="text" [(ngModel)]="txtEmailDomain">
			</mat-form-field>

			<mat-form-field class="full-width-input">
				<mat-label>AI Engine</mat-label>
				<mat-select [(ngModel)]="selectedAI" name="aipref">
					@for (aipref of AIEngineDataSource; track aipref) {
						<mat-option [value]="aipref.id">{{aipref.name}}</mat-option>
					}
				</mat-select>
			</mat-form-field>



			<button mat-flat-button color="primary" (click)="saveNew()">Save New Broker</button>

			<button mat-flat-button color="warn" (click)="cancelClicked(drawer)">Cancel</button>

		</mat-drawer>

		<div class="body-frame">
			<div class="nav-bar">
				<app-naviconbar></app-naviconbar>
			</div>

			<div class="nav-bar">
				<button mat-flat-button color="primary" (click)="drawer.open()">Add Broker</button>
				<button mat-flat-button color="primary" (click)="refreshItems()">Refresh Broker List</button>
			</div>

			<div>
				<table [ngClass]="{ 'loading-animation': isLoading }" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef> Id </th>
						<td mat-cell *matCellDef="let item"> {{item.id}} </td>
					</ng-container>

					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef> Name </th>
						<td mat-cell *matCellDef="let item"> {{item.name}} </td>
					</ng-container>

					<ng-container matColumnDef="city">
						<th mat-header-cell *matHeaderCellDef> City </th>
						<td mat-cell *matCellDef="let item"> {{item.city}} </td>
					</ng-container>

					<ng-container matColumnDef="state">
						<th mat-header-cell *matHeaderCellDef> State </th>
						<td mat-cell *matCellDef="let item"> {{item.state}} </td>
					</ng-container>

					<ng-container matColumnDef="contactPerson">
						<th mat-header-cell *matHeaderCellDef> Contact Person </th>
						<td mat-cell *matCellDef="let item"> {{item.contactPerson}} </td>
					</ng-container>

					<ng-container matColumnDef="defaultRecipientEmail">
						<th mat-header-cell *matHeaderCellDef> Default Recipient </th>
						<td mat-cell *matCellDef="let item"> {{item.defaultRecipientEmail }} </td>
					</ng-container>



					<ng-container matColumnDef="delButton">
						<th mat-header-cell *matHeaderCellDef> </th>
						<td mat-cell *matCellDef="let item"> <button mat-flat-button color="warn" (click)="deleteItem(item)">Delete</button> </td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;" (click)="rowClicked(row)"></tr>

				</table>
			</div>
		</div>

	</mat-drawer-container>
</div>

