import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmailRequest } from '../models/emailrequest.model';
import { ERPSubmissionAttempt } from '../models/erpsubmissionattempt.model';
import { UrlService } from './url.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ErpsubmissionattemptService {

	private svcUrl = '/ERPSubmissionAttempts';

	constructor(private http: HttpClient, private url: UrlService, private auth: AuthenticationService) { }

	getERPSubmissionAttempt(id: number): Observable<ERPSubmissionAttempt> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<ERPSubmissionAttempt>(this.url.getBaseURL() + this.svcUrl + '/' + id, { headers });
	}

	getERPSubmissionAttemptsForBrokerId(id: number, isDismissed?: boolean | null, itemStart?: number | null, itemCount?: number | null) {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		let params = new HttpParams()
			.set('IsDismissed', (isDismissed == null ? false : isDismissed))
			.set('ItemStart', (itemStart == null ? 0 : itemStart))
			.set('ItemCount', (itemCount == null ? 100 : itemCount));
		return this.http.get<ERPSubmissionAttempt[]>(this.url.getBaseURL() + this.svcUrl + '/Broker/' + id, { headers, params });
	}

	getERPSubmissionAttemptsForUserId(id: string, isDismissed?: boolean | null, itemStart?: number | null, itemCount?: number | null) {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		let params = new HttpParams()
			.set('IsDismissed', (isDismissed == null ? false : isDismissed))
			.set('ItemStart', (itemStart == null ? 0 : itemStart))
			.set('ItemCount', (itemCount == null ? 100 : itemCount));
		return this.http.get<ERPSubmissionAttempt[]>(this.url.getBaseURL() + this.svcUrl + '/User/' + id, { headers, params });
	}

	getERPSubmissionAttemptsForParsedEmailId(id: number) {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<ERPSubmissionAttempt[]>(this.url.getBaseURL() + this.svcUrl + '/ParsedEmail/' + id, { headers });
	}

	getEmailRequestForERPSubmissionAttemptId(id: number) {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<EmailRequest>(this.url.getBaseURL() + this.svcUrl + '/ERPSubmissionAttempt/' + id, { headers });
	}

	dismissERPSubmissionAttempt(id: number): Observable<ERPSubmissionAttempt> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<ERPSubmissionAttempt>(this.url.getBaseURL() + this.svcUrl + '/Dismiss/' + id, {}, { headers });
	}

	createERPSubmissionAttempt(parsedEmailId: number, connId: number, erpStatusId: number): Observable<ERPSubmissionAttempt> {
		// here, erpStatusId is either 0 = quoted or 1 = booked
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<ERPSubmissionAttempt>(this.url.getBaseURL() + this.svcUrl + '/CreateERPSubmissionAttempt/' + parsedEmailId + '/' + connId + '/' + erpStatusId, '{}', { headers });
	}

	submitToERP(erpSubmissionAttemptId: number): Observable<ERPSubmissionAttempt> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<ERPSubmissionAttempt>(this.url.getBaseURL() + this.svcUrl + '/SubmitToERP/' + erpSubmissionAttemptId, '{}', { headers });
	}

}
