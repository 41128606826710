<div class="container">
	<app-drop (file)="loadFile($event)"></app-drop>

	<ul>
		@for(item of list; track item) {
		<li>
			<strong>{{ item.subject[0].value }}</strong>
			<div>{{item.date[0].value}}</div>
			<div class="email-preview" [innerHTML]="item.body"></div>
		</li>
		}
	</ul>
</div>
