import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ERPConnection } from '../models/erpconnection.model';
import { SlimERPConnection } from '../models/slimerpconnection.model';
import { UrlService } from './url.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ErpconnectionService {

	private svcUrl = '/ERPConnections';

	constructor(private http: HttpClient, private url: UrlService, private auth: AuthenticationService) { }

	getERPConnections(): Observable<ERPConnection[]> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<ERPConnection[]>(this.url.getBaseURL() + this.svcUrl, { headers });
	}

	getERPConnection(id: number): Observable<ERPConnection> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<ERPConnection>(this.url.getBaseURL() + this.svcUrl + '/' + id, { headers });
	}

	createERPConnection(item: ERPConnection): Observable<ERPConnection> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<ERPConnection>(this.url.getBaseURL() + this.svcUrl, item, { headers });
	}

	getERPConnectionsForBrokerId(id: number): Observable<ERPConnection[]> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<ERPConnection[]>(this.url.getBaseURL() + this.svcUrl + '/Broker/' + id, { headers });
	}

	getSlimERPConnectionsForBrokerId(id: number): Observable<SlimERPConnection[]> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<SlimERPConnection[]>(this.url.getBaseURL() + this.svcUrl + '/SlimBroker/' + id, { headers });
	}

	setERPConnectionAsDefault(id: number): Observable<ERPConnection> {
		console.log(id);
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<ERPConnection>(this.url.getBaseURL() + this.svcUrl + '/SetERPConnectionAsDefault/' + id, {}, { headers });
	}


}
