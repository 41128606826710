import { Component } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Broker } from '../../core/models/broker.model';
import { ParsedEmail } from '../../core/models/parsedemail.model';
import { ERPSubmissionAttempt } from '../../core/models/erpsubmissionattempt.model';
import { User } from '../../core/models/user.model';
import { BrokerService } from '../../core/services/broker.service'; 
import { ErpconnectionService } from '../../core/services/erpconnection.service'; 
import { ErpsubmissionattemptService } from '../../core/services/erpsubmissionattempt.service';
import { EmailrequestService } from '../../core/services/emailrequest.service'; 
import { ParsedemailService } from '../../core/services/parsedemail.service'; 
import { UserService } from '../../core/services/user.service'; 
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { YesnoconfirmComponent } from '../../shared/components/yesnoconfirm/yesnoconfirm.component';
import { NaviconbarComponent } from '../../shared/components/naviconbar/naviconbar.component';
import { TruncatePipe } from '../../shared/pipes/truncate/truncate.pipe';


@Component({
  selector: 'app-erpsubmissionattempts',
  standalone: true,
  imports: [RouterModule, CommonModule, MatNativeDateModule, MatDatepickerModule, MatDialogModule, MatTooltipModule, MatChipsModule, MatRadioModule, MatIconModule, MatSelectModule, MatButtonModule, MatBottomSheetModule, MatSidenavModule, MatTableModule, MatInputModule, FormsModule, NaviconbarComponent, TruncatePipe, DatePipe],
  templateUrl: './erpsubmissionattempts.component.html',
  styleUrl: './erpsubmissionattempts.component.scss'
})
export class ErpsubmissionattemptsComponent {

	isBrokerLoading: boolean = false;
	isLoading: boolean = false;

	displayedColumns: string[] = ['id', 'createDate', 'lastUpdateDate', 'parsedEmailId', 'brokerId', 'userId', 'status', 'eRPCreatedId', 'delButton'];
	dataSource: ERPSubmissionAttempt[] = [];

	brokerDataSource: Broker[] = [];
	userDataSource: User[] = [];

	showFiller = false;
	showDismissed = false;

	selectedBroker: number = 0;
	selectedUser: string = '';
	searchSelection: number = 0;

	txtSender: string = "";
	txtRecipient: string = "";
	txtSubject: string = "";
	txtBody: string = "";

	constructor(private dataService: ErpsubmissionattemptService,
	private brokerDataService: BrokerService,
	private userDataService: UserService,
	private _snackBar: MatSnackBar,
	private yesnoConfirm: MatBottomSheet)
	{
		this.isBrokerLoading = true;
		this.brokerDataService.getBrokers().subscribe({
			next: x => 
			{
				this.isBrokerLoading = false;
				this.brokerDataSource = x;
				if(this.brokerDataSource.length == 1)
				{
					this.selectedBroker = this.brokerDataSource[0].id;
				}
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isBrokerLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Broker data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Broker data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Broker data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Broker data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Broker data: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});

		this.userDataService.getUsers().subscribe({
			next: x => 
			{
				this.userDataSource = x;
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching User data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching User data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching User data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching User data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching User data: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});

	}

	onIncludeDismissedChange(event: any)
	{
		this.showDismissed = event.selected;
	}

	openSnackBar(message: string, action: string)
	{
		this._snackBar.open(message, action,
		{
			horizontalPosition: 'center',
			verticalPosition: 'top',
			duration: 5000
		});
	}

	refreshItems()
	{
		// this is the Broker selection
		if(this.searchSelection == 1)
		{
			this.refreshItemsByBrokerId(this.selectedBroker);
		}
		// this is the User selection
		else if(this.searchSelection == 2)
		{
			this.refreshItemsByUserId(this.selectedUser);
		}
	}

	refreshItemsByBrokerId(brokerId: number)
	{
		this.isLoading = true;
		this.dataService.getERPSubmissionAttemptsForBrokerId(brokerId, this.showDismissed).subscribe({
			next: x => 
			{
				this.dataSource = x;
				this.isLoading = false;
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching ERP Submission Attempt data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching ERP Submission Attempt data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching ERP Submission Attempt data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching ERP Submission Attempt data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching ERP Submission Attempt data: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});
	}

	refreshItemsByUserId(userId: string)
	{
		this.isLoading = true;
		this.dataService.getERPSubmissionAttemptsForUserId(userId, this.showDismissed).subscribe({
			next: x => 
			{
				this.dataSource = x;
				this.isLoading = false;
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching ERP Submission Attempt data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching ERP Submission Attempt data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching ERP Submission Attempt data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching ERP Submission Attempt data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching ERP Submission Attempt data: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});
	}

	dismissItem(item: ERPSubmissionAttempt)
	{
		var conf = this.yesnoConfirm.open(YesnoconfirmComponent);
		conf.afterDismissed().subscribe(data =>
		{
			if(data)
			{
				// yes, go ahead and delete
				this.dataService.dismissERPSubmissionAttempt(item.id).subscribe({
					next: x => 
					{
						this.openSnackBar("ERP Submission Attempt dismissed.  Id: " + item.id, "Dismiss");
						this.refreshItems();	
					},
					error: (err: HttpErrorResponse) =>
					{
						if (err.error instanceof ErrorEvent) {
							// Client-side or network error
							this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
						} 
						else {
							// Backend error
							switch (err.status) {
								case 400:
									this.openSnackBar("Error dismissing ERP Submission Attempt: 400 Bad Request.", "Dismiss");
									break;
								case 401:
									this.openSnackBar("Error dismissing ERP Submission Attempt: 401 Unauthorized.", "Dismiss");
									break;
								case 404:
									this.openSnackBar("Error dismissing ERP Submission Attempt: 404 Not Found.", "Dismiss");
									break;
								case 500:
									this.openSnackBar("Error dismissing ERP Submission Attempt: 500 Server Error", "Dismiss");
									break;
								default:
									this.openSnackBar("Error dismissing ERP Submission Attempt: Unknown Error", "Dismiss");
									break;
							}
						}
						console.log(err);
					}
				});


			}
			else
			{
				// no, do not delete
			}
		});
	}



	rowClicked(drawer: any, row: ERPSubmissionAttempt)
	{
		drawer.open();
	}

	closeClicked(drawer: any)
	{
		drawer.close();	
	}

}
