import { Component } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSelectModule } from '@angular/material/select';
import { YesnoconfirmComponent } from '../../shared/components/yesnoconfirm/yesnoconfirm.component';
import { NaviconbarComponent } from '../../shared/components/naviconbar/naviconbar.component';
import { UserService } from '../../core/services/user.service'; 
import { User } from '../../core/models/user.model';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [RouterModule, CommonModule, MatTooltipModule, MatIconModule, MatSelectModule, MatButtonModule, MatBottomSheetModule, MatSidenavModule, MatTableModule, MatInputModule, FormsModule, NaviconbarComponent, DatePipe],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent {

	isLoading: boolean = false;

	dataSource: User[] = [];

	displayedColumns: string[] = ['id', 'name', 'email', 'brokerId', 'lastlogin', 'lastip', 'loginscount', 'createdat', 'updatedat', 'emailverified'];

	constructor(private dataService: UserService,
	private _snackBar: MatSnackBar,
	private yesnoConfirm: MatBottomSheet)
	{
		this.refreshItems();
	}

	refreshItems()
	{
		this.isLoading = true;
		this.dataService.getAllUsers().subscribe({
			next: x => 
			{
				this.dataSource = x;
				this.isLoading = false;
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching User data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching User data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching User data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching User data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching User data: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}

	cancelClicked(drawer: any)
	{
		drawer.close();	
	}

	rowClicked(row: any)
	{

	}

	openSnackBar(message: string, action: string)
	{
		this._snackBar.open(message, action,
		{
			horizontalPosition: 'center',
			verticalPosition: 'top',
			duration: 5000
		});
	}

}
