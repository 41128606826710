<div class="parent-frame">
	<mat-drawer-container class="drawer-container" autosize>
		<mat-drawer #drawer class="drawer-flyout" mode="over">
			<button mat-flat-button color="warn" (click)="cancelClicked(drawer)">Close</button>
		</mat-drawer>

		<div class="body-frame">

			<div class="nav-bar">
				<app-naviconbar></app-naviconbar>
			</div>

			<div class="nav-bar">
				<mat-radio-group [ngClass]="{ 'loading-animation': isBrokerLoading }" aria-label="Broker or User Search" [(ngModel)]="searchSelection">
					<mat-radio-button value="1">
						<!-- broker combo box dropdown -->
						<mat-form-field>
							<mat-label>Broker</mat-label>
							<mat-select [(ngModel)]="selectedBroker" name="broker">
								@for (broker of brokerDataSource; track broker) {
									<mat-option [value]="broker.id">{{broker.name}}</mat-option>
								}
							</mat-select>
						</mat-form-field>
					</mat-radio-button>
					<mat-radio-button value="2">
						<!-- user combo box dropdown -->
						<mat-form-field>
							<mat-label>User</mat-label>
							<mat-select [(ngModel)]="selectedUser" name="user">
								@for (user of userDataSource; track user) {
									<mat-option [value]="user.user_id">{{user.name}}</mat-option>
								}
							</mat-select>
						</mat-form-field>
					</mat-radio-button>
				</mat-radio-group>

				<button mat-flat-button color="primary" (click)="refreshItems()">Refresh Email Requests List</button>

				<mat-chip-option [selected]="showDismissed" (selectionChange)="onIncludeDismissedChange($event)" style="height: 50%">Include dismissed items</mat-chip-option>

			</div>

			<div class="data-table">
				<table [ngClass]="{ 'loading-animation' : isLoading }" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef> Id </th>
						<td mat-cell *matCellDef="let item"> {{item.id}} </td>
					</ng-container>

					<ng-container matColumnDef="createDate">
						<th mat-header-cell *matHeaderCellDef> Create Date </th>
						<td mat-cell *matCellDef="let item"> {{item.createDate | date:'mediumDate'}} </td>
					</ng-container>

					<ng-container matColumnDef="emailSender">
						<th mat-header-cell *matHeaderCellDef> Sender </th>
						<td mat-cell *matCellDef="let item" [ngClass]="{'dismissed': item.isDismissed}"> {{item.emailSender}} </td>
					</ng-container>

					<ng-container matColumnDef="emailSubject">
						<th mat-header-cell *matHeaderCellDef> Subject </th>
						<td mat-cell *matCellDef="let item" [ngClass]="{'dismissed': item.isDismissed}"> {{item.emailSubject | truncate:500}} </td>
					</ng-container>

					<ng-container matColumnDef="emailBody">
						<th mat-header-cell *matHeaderCellDef> Body </th>
						<td mat-cell *matCellDef="let item" [ngClass]="{'dismissed': item.isDismissed}"> {{item.emailBody | truncate:500}} </td>
					</ng-container>

					<ng-container matColumnDef="emailAttachment">
						<th mat-header-cell *matHeaderCellDef> Attachment </th>
						<td mat-cell *matCellDef="let item" >@if(item.attachmentGuid) {	<mat-icon matTooltip="Attachment" style="color: black">attach_file</mat-icon> }</td>
					</ng-container>

					<ng-container matColumnDef="delButton">
						<th mat-header-cell *matHeaderCellDef> </th>
						<td mat-cell *matCellDef="let item"> <button mat-flat-button color="warn" (click)="dismissItem(item)">Dismiss</button> </td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>

				</table>
			</div>
		</div>
	</mat-drawer-container>
</div>
