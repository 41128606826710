import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AIEngine } from '../models/aiengine.model';
import { UrlService } from './url.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AiengineService {

	private svcUrl = '/AIEngines';

	constructor(private http: HttpClient, private url: UrlService, private auth: AuthenticationService) { 

	}

	getAIEngines(): Observable<AIEngine[]> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<AIEngine[]>(this.url.getBaseURL() + this.svcUrl, { headers });
	}

	getAIEngine(id: number): Observable<AIEngine> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.get<AIEngine>(this.url.getBaseURL() + this.svcUrl + '/' + id, { headers });
	}

	createAIEngine(item: AIEngine): Observable<AIEngine> {
		const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.getBearerToken());
		return this.http.post<AIEngine>(this.url.getBaseURL() + this.svcUrl, item, { headers });
	}

}

