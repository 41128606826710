import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

	constructor() { }

	getBaseURL() {
		return environment.apiUrl;
	}
}
